import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import g from "src/utils/MuiStyle/Global";
import c from "./InfoStyle";
import TitleMain from "src/components/atoms/TitleMain";
import { useEffect, useState } from "react";
import axios from "axios";
import useStore from "src/hooks/useStore";
import SwalCatch from "src/utils/helpers/SwalCatch";
import SwalSuccess from "src/utils/helpers/SwalSuccess";
import { useDispatch } from "react-redux";
import { setData } from "src/store/profileAccount";

const Info = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const data = store.profileAccount.data;

  const [imgShow, setImgShow] = useState("/images/dummy/avatar.jpg");
  const [isEdit, setIsEdit] = useState(false);
  const [formField, setFormField] = useState({
    name: data.name,
    email: data.email,
    img: data.img,
  });

  const handleChange = (param) => (e) => {
    if (param === "image") {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onload = (event) => {
        setImgShow(`${event.target.result}`);
      };
      reader.readAsDataURL(file);
    }
  };

  const onHandleEdit = () => {
    setIsEdit(true);
  };

  const onHandleChange = (key) => (e) => {
    setFormField((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const onHandleUpdate = () => {
    axios
      .post("/api/user/update", formField)
      .then((res) => {
        dispatch(setData(res?.data?.date));
        SwalSuccess(res?.data?.message);
        setIsEdit(false);
      })
      .catch((err) => {
        const errors = [{ message: err?.response?.data?.message }];
        SwalCatch(errors);
      });
  };

  return (
    <Box component="form" sx={c.wrapperInfo}>
      <Box component={"label"} htmlFor="fileImg" sx={c.infoImgWrapper}>
        <Box
          sx={c.infoImg}
          component="img"
          src={data.img ?? imgShow}
          alt="Image Account"
        />
      </Box>
      <Box
        component={"input"}
        type="file"
        id="fileImg"
        accept="image/jpeg, image/png"
        style={{ display: "none" }}
        onChange={handleChange("image")}
      />
      <Box sx={c.infoContent}>
        <TitleMain text="Profiel" sxs={c.titleInfoContent} />
        <Box sx={c.wrapperInfoContent}>
          <Box sx={c.listInfoContent}>
            <Typography variant="body1" sx={c.keyListInfoContent}>
              Naam
            </Typography>
            {!isEdit ? (
              <Typography variant="body1" sx={c.valueListInfoContent}>
                {data.name}
              </Typography>
            ) : (
              <TextField
                sx={{
                  width: "100%",
                }}
                id="name"
                label="Naam"
                variant="outlined"
                name="name"
                type="text"
                onChange={onHandleChange("name")}
                required
                value={formField.name}
              />
            )}
          </Box>
          <Divider />
          <Box sx={c.listInfoContent}>
            <Typography variant="body1" sx={c.keyListInfoContent}>
              E-mail
            </Typography>
            {!isEdit ? (
              <Typography variant="body1" sx={c.valueListInfoContent}>
                {data.email}
              </Typography>
            ) : (
              <TextField
                sx={{
                  width: "100%",
                }}
                id="email"
                label="E-mail"
                variant="outlined"
                name="email"
                type="email"
                onChange={onHandleChange("email")}
                required
                value={formField.email}
              />
            )}
          </Box>
          <Divider />
        </Box>
        <Box>
          {!isEdit ? (
            <Button variant="contained" onClick={onHandleEdit}>
              Profiel bewerken
            </Button>
          ) : (
            <>
              <Button variant="contained" onClick={onHandleUpdate}>
                Profiel bijwerken
              </Button>
              <Button
                sx={{ ...g.button.secondary, marginLeft: "10px" }}
                variant="contained"
                onClick={() => setIsEdit(false)}
              >
                Annuleren
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Info;
