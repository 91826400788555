import { Box } from "@mui/material";
import c from "./WrapperContentStyle";
import Footer from "src/components/backend/Dashboard/Footer/Footer";

/**
 * @typedef {object} WrapperContent
 * @property {any} children
 * @property {boolean} [isLight]
 * @property {boolean} [isSm]
 * @property {boolean} [isFooter]
 * @property {object} [sxs]
 *
 * @param {WrapperContent} param1
 */

const WrapperContent = ({ children, isLight, isSm, sxs, isFooter = true }) => {
  return (
    <Box
      sx={{
        ...c.wrapper,
        ...sxs,
        ...(isLight ? c.light : {}),
        ...(isSm ? c.wrapperSm : {}),
      }}
    >
      <Box sx={c.container}>{children}</Box>
      {isFooter && (
        <>
          <br />
          <hr />
          <Footer />
        </>
      )}
    </Box>
  );
};

export default WrapperContent;
