import { Box, Typography } from "@mui/material";

const FormPopupSuccess = () => {
  return (
    <Box
      sx={{
        paddingBlock: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box
        component="img"
        src="/images/success-illustration.png"
        alt="Image Success"
      />
      <Typography variant="h4" textAlign={"center"}>
        Bedankt voor je sollicitatie. We nemen zo snel mogelijk contact met je
        op{" "}
      </Typography>
    </Box>
  );
};

export default FormPopupSuccess;
