const { AsSxObject } = require("src/utils/AsSxObject");

const FaqChildStyle = AsSxObject({
  modal: {
    backgroundColor: "rgba(0,0,0, 0.4)",
    overflow: "auto",
  },
  modalWrapper: {
    padding: "20px",
    maxWidth: "1300px",
    marginInline: "auto",
    "&:focus-visible": {
      outline: "none",
    },
  },
  footerSlide: {
    display: "flex",
    justifyContent: "flex-end",
  },
  footerSlidePlusMinus: {
    display: "flex",
    gap: "16px",
  },
});

export default FaqChildStyle;
