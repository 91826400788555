const { AsSxObject } = require("src/utils/AsSxObject");

const IntroStyle = AsSxObject({
  wrapper: {
    display: "flex",
    gap: "46px",
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
    },
  },
  content: {
    flex: "0 0 calc(45% - (46px * 1 / 2))",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  title: {
    "@media(min-width: 1368px)": {
      marginTop: "35px",
    },
    "@media(max-width: 600px)": {
      marginBottom: "16px",
    },
  },
  image: {
    width: "0px",
    height: "248px",
    objectFit: "cover",
    borderRadius: "20px",
    flex: "0 0 calc(55% - (46px * 1 / 2))",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
    "@media(min-width: 600px) and (max-width: 1020px)": {
      height: "324px",
    },
  },
});

export default IntroStyle;
