import axios from "axios";
import { useEffect, useLayoutEffect } from "react";
import useStore from "./useStore";

const useAxiosInterceptor = () => {
  const store = useStore();
  const token = store.userAccount.userToken;

  useLayoutEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (request) => {
        if (token) {
          request.headers["Authorization"] = `Bearer ${token}`;
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  });
};

export default useAxiosInterceptor;
