import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Home
 * @property {object} [sxs]
 *
 * @param {Home} param1
 */

export default function IcEducationLabel({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#2946D6" />
        <path
          d="M19.2199 18.4268C19.6666 18.1334 20.2532 18.4534 20.2532 18.9868V19.8468C20.2532 20.6934 19.5932 21.6001 18.7999 21.8668L16.6732 22.5734C16.2999 22.7001 15.6932 22.7001 15.3266 22.5734L13.1999 21.8668C12.3999 21.6001 11.7466 20.6934 11.7466 19.8468V18.9801C11.7466 18.4534 12.3332 18.1334 12.7732 18.4201L14.1466 19.3134C14.6732 19.6668 15.3399 19.8401 16.0066 19.8401C16.6732 19.8401 17.3399 19.6668 17.8666 19.3134L19.2199 18.4268Z"
          fill="#F4F6FF"
        />
        <path
          d="M21.32 12.3068L17.3267 9.68679C16.6067 9.21345 15.42 9.21345 14.7 9.68679L10.6867 12.3068C9.40001 13.1401 9.40001 15.0268 10.6867 15.8668L11.7533 16.5601L14.7 18.4801C15.42 18.9535 16.6067 18.9535 17.3267 18.4801L20.2533 16.5601L21.1667 15.9601V18.0001C21.1667 18.2735 21.3933 18.5001 21.6667 18.5001C21.94 18.5001 22.1667 18.2735 22.1667 18.0001V14.7201C22.4333 13.8601 22.16 12.8601 21.32 12.3068Z"
          fill="#F4F6FF"
        />
      </svg>
    </SvgIcon>
  );
}
