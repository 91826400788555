import { Box, Button, Container, Typography } from "@mui/material";
import c from "./AlgemeneInfoStyle";
import g from "src/utils/MuiStyle/Global";
import YtEmbed from "src/components/moleculs/YtEmbed";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const AlgemeneInfo = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Algemene info </title>
        <meta
          name="description"
          content="Er bestaan verschillende opties om rijinstructeur te worden (Syntra, rijscholen of zelfstudie). Vind hier al het nodige studiemateriaal."
        />
      </Helmet>
      <Container sx={c.container}>
        <Box sx={c.wrapper} className="Algemene">
          <Box sx={c.content}>
            <Typography variant="h2" sx={c.contentH2}>
              Algemene Info
            </Typography>
            <Typography variant="body1" sx={c.contentDescAlgemeneInfo}>
              Wil je graag zelf rijles geven? Dan vind je hier alle nodige{" "}
              <b>studiemateriaal</b> terug.
              <br />
              <br />
              Er bestaan verschillende lespakketten om rijinstructeur te worden.
              Ofwel volg je een opleiding rijschoollesgever via <b>
                Syntra
              </b>{" "}
              (erkend opleidingsinstituut), in samenwerking met de{" "}
              <b>rijscholen</b>, ofwel studeer je op eigen tempo via{" "}
              <b>zelfstudie</b>. Voor beide opties vind je hier al het nodige
              studiemateriaal terug.
            </Typography>
            <Box sx={c.cta}>
              <Link to="/prijs-rijinstructeur-lespakketten">
                <Button variant="contained">Rijinstructuur lespakketten</Button>
              </Link>
              <Link to="/login">
                <Button variant="contained" sx={g.button.secondary}>
                  Ga naar platform
                </Button>
              </Link>
            </Box>
          </Box>
          <Box sx={c.image}>
            <Box
              sx={c.img}
              component="img"
              alt="Car Image"
              src="/images/dummy/IMG_5444.png"
            />
          </Box>
        </Box>
        <Box sx={c.wrapper} className="Algemene">
          <Box sx={c.image}>
            <YtEmbed url="https://www.youtube.com/watch?v=eJ_jN7kNG5g" />
          </Box>
          <Box sx={c.content}>
            <Typography variant="h2" sx={c.contentH2}>
              Rijinstructeur worden: de voorwaarden
            </Typography>
            <Typography variant="body1">
              Om rijinstructeur te worden is het van belang om een aantal zaken
              te checken. Afhankelijk van het lespakket dat je wil onderrichten,
              moet je voldoen aan enkele voorwaarden. Deze voorwaarden worden
              bepaald door de overheid. In de volgende video kan je bekijken wat
              de voorwaarden zijn en wanneer je jou moet inschrijven voor
              deelname aan de examens.
            </Typography>
          </Box>
        </Box>
        <Box sx={c.wrapper} className="Algemene">
          <Box sx={c.content}>
            <Typography variant="h2" sx={c.contentH2}>
              Profiel van de rijinstructeur
            </Typography>
            <Typography variant="body1">
              Zijn de wettelijke voorwaarden geen probleem dan is het van belang
              dat jouw profiel geschikt is voor de job als rijinstructeur. Welke
              verwachtingen men stelt kan je zien in volgende video.
            </Typography>
          </Box>
          <Box sx={c.image}>
            <YtEmbed url="https://www.youtube.com/watch?v=Im76kmuy0Zo" />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AlgemeneInfo;
