import Login from "src/pages/auth/Login";
import ReqResetPassword from "src/pages/auth/ReqResetPassword";
import ResetPasswordPage from "src/pages/auth/ResetPasswordPage";
import Register from "src/pages/auth/Register";
import { Navigate } from "react-router-dom";

const authRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register/zelfstudie",
    element: <Register />,
  },
  {
    path: "/registreer/cursist",
    element: <Navigate to="/register" replace />,
  },
  {
    path: "/reset-password",
    element: <ReqResetPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPasswordPage />,
  },
];

export default authRoutes;
