import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import c from "./TabsSectionStyle";
import TabPanel from "./TabPanel";
import AlgemeneInfo from "./AlgemeneInfo";
import Studiemateriaal from "./Studiemateriaal";
import BrevetRijinstructeur from "./BrevetRijinstructeur";
import Prijs from "./Prijs";
import Contact from "./Contact";
import SocialMedia from "src/components/atoms/SocialMedia/SocialMedia";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `home-tab-${index}`,
    "aria-controls": `home-tabpanel-${index}`,
  };
}

const TabsSection = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const data = [
    {
      param: "/algemene-info",
      label: "Algemene info",
      element: <AlgemeneInfo />,
    },
    {
      param: "/studiemateriaal",
      label: "Studiemateriaal",
      element: <Studiemateriaal />,
    },
    {
      param: "/brevet-rijinstructeur",
      label: "Brevet rijinstructeur",
      element: <BrevetRijinstructeur />,
    },
    {
      param: "/prijs-rijinstructeur-lespakketten",
      label: "Prijs rijinstructeur lespakketten",
      element: <Prijs />,
    },
    {
      param: "/contact",
      label: "Contact",
      element: <Contact />,
    },
  ];

  useEffect(() => {
    data.map((item, index) => {
      if (item.param === location.pathname) {
        setValue(index);
        return true;
      }
      return false;
    });
  }, [location]);

  const samePageLinkNavigation = (event) => {
    if (
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return false;
    }
    return true;
  };

  const handleChange = (event, newValue) => {
    if (
      event.type !== "click" ||
      (event.type === "click" && samePageLinkNavigation(event))
    ) {
      setValue(newValue);
    }
  };

  return (
    <Box sx={c.wrapper}>
      <Container>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          sx={c.nav}
          onChange={handleChange}
        >
          {data.map((item, i) => {
            return (
              <Tab
                key={item.label}
                sx={c.navItem}
                label={item.label}
                component={Link}
                to={item.param}
                {...a11yProps(i)}
              />
            );
          })}
        </Tabs>
      </Container>
      {data.map((item, i) => {
        return (
          <TabPanel key={item.label} value={value} index={i}>
            {item.element}
          </TabPanel>
        );
      })}
      <SocialMedia sxs={c.socialMedia} isNone />
    </Box>
  );
};

export default TabsSection;
