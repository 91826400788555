const { AsSxObject } = require("src/utils/AsSxObject");

const AccountStyle = AsSxObject({
  wrapper: {
    marginTop: "60px",
    "@media(max-width: 1020px)": {
      marginTop: "0",
    },
  },
  wrapperPaket: {
    marginTop: "112px",
    "@media(max-width: 1020px)": {
      marginTop: "60px",
    },
  },
  paketList: {
    marginBlock: "45px",
    display: "flex",
    flexDirection: "column",
    rowGap: "30px",
  },
});

export default AccountStyle;
