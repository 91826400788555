import Menu from "./Menu";
import c from "./SidebarStyle";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import hamburger from "src/components/atoms/icons/hamburger.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "src/store/userAccount";
import { ExpandMore, ChevronRight, Close } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { ListMenu } from "./ListMenu";
import { LogoutIcon } from "src/components/atoms/icons/Svg";
import useStore from "src/hooks/useStore";
import useRole from "src/hooks/useRole";
import { Box } from "@mui/material";

/**
 * @typedef {object} Sidebar
 * @property {boolean} [isSm]
 * @property {array} [data]
 * @property {string} [uri]
 *
 * @param {Sidebar} param1
 */

const Sidebar = ({ isSm = false, data, uri = "slide" }) => {
  const refMenu = useRef(null);
  const loc = useLocation().pathname;
  const location = "/" + loc.split("/")[1];

  const toggleMenu = () => {
    refMenu.current.classList.toggle("open");
  };

  const dispatch = useDispatch();
  const store = useStore();
  const dataUser = store.profileAccount.data;
  // @ts-ignore
  const userRole = useRole();

  const handleLogout = () => {
    dispatch(setToken(null));
  };

  const [open, setOpen] = useState(0);

  const handleClick = (no) => () => {
    if (open === no) {
      setOpen(0);
    } else {
      setOpen(no);
    }
  };

  const HandleActiveMenu = (itemChild, no) => {
    useEffect(() => {
      if (itemChild.url === loc && open !== no) {
        setOpen(no);
      }
    }, [no, itemChild]);
  };

  return (
    <>
      <Box
        sx={{ ...c.sidebarWrapper, ...(isSm ? c.sidebarWrapperSm : {}) }}
        ref={refMenu}
        className="Sidebar"
      >
        <Box sx={isSm ? c.asideWrapperSm : c.asideWrapper}>
          <Link to="/">
            <Box
              sx={c.logo}
              component="img"
              alt="logo"
              src={isSm ? "/images/logo-sm.svg" : "/images/logo.svg"}
            />
          </Link>
          <Box sx={{ ...c.mainMenu, ...(isSm ? c.mainMenuSm : {}) }}>
            {ListMenu(userRole).mainMenu.map((item, index) => {
              const mainUrl = "/" + item.url.split("/")[1];
              return (
                <Menu
                  key={`${item.url}-${index}`}
                  isSm={isSm}
                  isActive={location === mainUrl}
                  to={item.url}
                  text={item.name}
                  icon={location === mainUrl ? item.iconActive : item.icon}
                />
              );
            })}
          </Box>
          <Box sx={c.footMenu}>
            <Menu
              isSm={isSm}
              isActive={false}
              to="/account"
              text={dataUser?.name}
              imageSrc={
                dataUser
                  ? dataUser.img ?? "/images/dummy/avatar.jpg"
                  : "/images/dummy/avatar.jpg"
              }
              isSlim={true}
            />
            <Divider sx={c.divider} />
            {ListMenu(userRole).footMenu.map((item, index) => {
              return (
                <Menu
                  key={`${item.url}-${index}`}
                  isSm={isSm}
                  isActive={location === item.url}
                  to={item.url}
                  text={item.name}
                  icon={location === item.url ? item.iconActive : item.icon}
                  isSlim
                />
              );
            })}
            <Menu
              isSm={isSm}
              isActive={false}
              to="/"
              onClick={handleLogout}
              text="Afmelden"
              icon={<LogoutIcon />}
              isSlim={true}
            />
          </Box>
        </Box>
        <Box sx={isSm ? c.subSidebarWrapperSm : c.subSidebarWrapper}>
          <Box sx={c.subSidebarCloseBtn} onClick={toggleMenu}>
            <Close />
          </Box>
          <List sx={{ width: "100%" }}>
            {data?.map((item, index) => {
              const no = index + 1;
              return (
                <Box
                  key={item.id}
                  sx={{
                    ...(item.isNoBorder
                      ? c.subSidebarListBoxNoBorder
                      : c.subSidebarListBox),
                  }}
                >
                  <MenuParent
                    item={item}
                    loc={loc}
                    no={no}
                    uri={uri}
                    // @ts-ignore
                    open={open}
                    handleClick={handleClick}
                  />

                  <MenuChild
                    item={item}
                    loc={loc}
                    no={no}
                    uri={uri}
                    HandleActiveMenu={HandleActiveMenu}
                    open={open}
                  />
                </Box>
              );
            })}
          </List>
        </Box>
      </Box>
      <Box sx={c.mobileNav}>
        <Box
          sx={c.mobileLogo}
          component="img"
          alt="logo"
          src="/images/logo-sm.svg"
        />
        <Box
          sx={c.mobileHamburger}
          onClick={toggleMenu}
          component="img"
          src={hamburger}
        />
      </Box>
    </>
  );
};

const MenuParent = (props) => {
  const { item, loc, handleClick, no, uri, open = false } = props;
  return item.childs.length === 0 ? (
    <Link to={`/${uri}/${item.slug}`} style={c.subSidebarLink}>
      <ButtonEl {...props}></ButtonEl>
    </Link>
  ) : (
    <ButtonEl {...props}></ButtonEl>
  );
};

const MenuChild = ({ open, no, item, HandleActiveMenu, uri, loc }) => {
  return (
    <Collapse in={open === no} timeout="auto" unmountOnExit>
      <List sx={c.subSidebarListItemChild} component="div" disablePadding>
        {item?.child?.map((itemChild, index) => {
          HandleActiveMenu(itemChild, no);
          return (
            <ListItemButton key={itemChild.id}>
              {itemChild.childs.length === 0 && (
                <Link
                  to={`/${uri}/${item.slug}`}
                  className="streched-link"
                ></Link>
              )}
              <ListItemText
                secondary={itemChild.title}
                sx={{
                  ...c.subSidebarListItemChildLink,
                  ...(itemChild.url === loc
                    ? c.subSidebarListItemChildLinkActive
                    : {}),
                }}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Collapse>
  );
};

const ButtonEl = ({ item, loc, handleClick, no, uri, open }) => {
  const isNoBorder = item.childs.length !== 0;
  return (
    <ListItemButton
      sx={{
        ...(isNoBorder ? c.subSidebarListItemNoBorder : c.subSidebarListItem),
        ...(item.url === loc ? c.subSidebarListItemLinkActive : {}),
      }}
      onClick={item.childs.length > 0 ? handleClick(no) : null}
    >
      {item.childs.length > 0 ? (
        <>
          <ListItemText
            sx={{
              ...c.subSidebarText,
              ...(open === no ? c.subSidebarActive : {}),
            }}
            primary={item.title}
          />
          {!isNoBorder ? open === no ? <ExpandMore /> : <ChevronRight /> : ""}
        </>
      ) : (
        <>
          <ListItemText
            sx={{
              ...c.subSidebarText,
              ...(open === no ? c.subSidebarActive : {}),
            }}
            primary={item.title}
          />
          {!item.isNoBorder ? (
            open === no ? (
              <ExpandMore />
            ) : (
              <ChevronRight />
            )
          ) : (
            <></>
          )}
        </>
      )}
    </ListItemButton>
  );
};

export default Sidebar;
