import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const AuthFooterStyle = AsSxObject({
  wrapper: {
    display: "flex",
    justyfyContent: "flex-start",
    position: "absolute",
    bottom: 0,
    left: "0",
    width: "100%",
    paddingInline: "65px",
    marginBottom: "32px",
    columnGap: "72px",
    "@media(max-width: 600px)": {
      paddingInline: "24px",
      columnGap: "30px",
      marginBottom: "16px",
      position: "relative",
    },
    "@media(max-width: 1020px)": {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  item: {
    fontFamily: "Metropolis-regular",
    fontSize: "12px",
    color: g.color.black,
    flex: "0 0 none",
    whiteSpace: "break-spaces",
    "&:last-child": {
      flex: "auto",
      marginLeft: "auto",
      display: "flex",
      justifyContent: "flex-end",
      color: g.color.white,
      "@media(max-width: 1020px)": {
        flex: "0 0 100%",
        justifyContent: "center",
        color: g.color.black,
      },
    },
  },
});

export default AuthFooterStyle;
