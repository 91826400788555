const { AsSxObject } = require("src/utils/AsSxObject");

const TabsSectionStyle = AsSxObject({
  box: {
    padding: "0px",
    marginBlock: "0",
  },
});

export default TabsSectionStyle;
