import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const PriceListCardStyle = AsSxObject({
  box: {
    padding: "0px",
    marginBlock: "0",
  },
  listTitle: {
    title: {
      fontFamily: "Metropolis-medium",
    },
  },
  listSubTitle: {
    marginBlock: "24px 8px",
  },
  card: {
    marginTop: "30px",
    boxShadow: "0px 3px 6px #00000019",
    borderRadius: "20px",
    overflow: "hidden",
  },
  cardHeading: {
    color: g.color.white,
    backgroundColor: g.color.primary,
    padding: "30px 0 24px 0",
    display: "flex",
    "@media(max-width: 600px)": {
      display: "none",
    },
  },
  cardBody: {
    paddingBlock: "32px",
    display: "flex",
    "@media(max-width: 600px)": {
      flexDirection: "column",
    },
  },
  cardBodyTitle: {
    marginBottom: "40px",
    "@media(max-width: 600px)": {
      marginBottom: "18px",
    },
  },
  cardColumn: {
    flex: "0 0 33.333%",
    paddingInline: "24px",
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 600px)": {
      paddingBlock: "30px",
    },
    hasBorderInline: {
      borderInline: "solid .3px #000000",
      "@media(max-width: 600px)": {
        paddingBlock: "30px",
        borderInline: "none",
        borderBlock: "solid .1px #000000",
      },
    },
  },
  cardFooter: {
    marginTop: "52px",
    paddingInline: "0",
    flex: "auto",
    display: "flex",
    alignItems: "flex-end",
    "@media(max-width: 600px)": {
      marginTop: "16px",
    },
  },
  cardTitleMobile: {
    color: g.color.primary,
    marginBottom: "16px",
    "@media(min-width: 600px)": {
      display: "none",
    },
  },
});

export default PriceListCardStyle;
