import { AsSxObject } from "src/utils/AsSxObject";

const DownloadListStyle = AsSxObject({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default DownloadListStyle;
