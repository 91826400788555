const { AsSxObject } = require("src/utils/AsSxObject");

const SwipperStyle = AsSxObject({
  wrapper: {
    marginTop: "40px",
    position: "relative",
  },
  wraperSliderItem: {
    minHeight: "500px",
    maxHeight: "95vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0,0,0, 0.05)",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0, 0.1)",
      borderRadius: "50px",
    },
  },
  popup: {
    position: "absolute",
    right: "30px",
    top: "30px",
    cursor: "pointer",
    zIndex: "9",
  },
});

export default SwipperStyle;
