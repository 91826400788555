import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Modelles
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Modelles} param1
 */

export default function ModellesIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.16"
        height="27.318"
        viewBox="0 0 29.16 27.318"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_198"
              data-name="Rectangle 198"
              width="29.16"
              height="27.318"
              fill="none"
            />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect
              id="Rectangle_197"
              data-name="Rectangle 197"
              width="29.157"
              height="27.315"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Component_13_9"
          data-name="Component 13 – 9"
          transform="translate(0.001 0.003)"
        >
          <g
            id="Group_1400"
            data-name="Group 1400"
            transform="translate(0 -0.003)"
          >
            <g
              id="Group_1399"
              data-name="Group 1399"
              transform="translate(-0.001 0)"
              clipPath="url(#clip-path)"
            >
              <path
                id="Path_462"
                data-name="Path 462"
                d="M14.583,27.318a3.158,3.158,0,0,1-.68-.074,3.3,3.3,0,0,1,.012-6.454,3.2,3.2,0,0,1,.659-.069,3.276,3.276,0,0,1,3,1.946.519.519,0,0,0,.509.366c.023,0,.047,0,.071,0,.215-.016.438-.021.653-.026a6.707,6.707,0,0,0,1.283-.112,3.542,3.542,0,0,0,2.707-3.4l0-.942c.011-2.824.023-5.744-.01-8.616A8.243,8.243,0,0,0,15.979,2.1a8.785,8.785,0,0,0-1.384-.111,8.176,8.176,0,0,0-8.08,6.661,8.829,8.829,0,0,0-.151,1.688c-.01,2.166-.009,4.367-.008,6.5v2.4A1.018,1.018,0,0,1,5.209,20.4H5.1c-.189,0-.378,0-.568.006s-.376.006-.563.006a8.738,8.738,0,0,1-1.107-.057A3.2,3.2,0,0,1,.05,17.668.521.521,0,0,0,0,17.541l0-.005V12.064a4.072,4.072,0,0,1,.664-1.538A3.3,3.3,0,0,1,3.242,9.213c.123,0,.246-.005.369-.005l.316,0,.338,0h.14l.019-.139A10.21,10.21,0,0,1,8.9,1.737,9.8,9.8,0,0,1,14.523,0c.187,0,.377,0,.566.015a9.949,9.949,0,0,1,6.473,2.765,10.157,10.157,0,0,1,3.157,6.266l.017.134.135.006c.165.008.329.012.492.015a8.326,8.326,0,0,1,1.02.064,3.27,3.27,0,0,1,2.769,3.2c.011,1.659.011,3.193,0,4.689A3.3,3.3,0,0,1,25.89,20.4l-.38,0h-.791l-.153.532a5.623,5.623,0,0,1-5.32,4.073l-.145,0c-.163,0-.325,0-.488,0s-.34,0-.51-.008h-.036a.47.47,0,0,0-.478.326,3.287,3.287,0,0,1-3.005,1.987"
                transform="translate(-0.001 0)"
              />
            </g>
          </g>
          <g id="Group_1398" data-name="Group 1398" transform="translate(0 0)">
            <g
              id="Group_1397"
              data-name="Group 1397"
              transform="translate(0 0)"
              clipPath="url(#clip-path-2)"
            >
              <path
                id="Path_461"
                data-name="Path 461"
                d="M14.581,27.315a3.154,3.154,0,0,1-.679-.074,3.3,3.3,0,0,1,.012-6.453,3.2,3.2,0,0,1,.659-.069,3.275,3.275,0,0,1,3,1.946.52.52,0,0,0,.509.366l.071,0c.215-.016.438-.021.653-.026a6.721,6.721,0,0,0,1.283-.112,3.542,3.542,0,0,0,2.707-3.4l0-.942c.011-2.824.023-5.744-.01-8.616A8.243,8.243,0,0,0,15.976,2.1a8.761,8.761,0,0,0-1.384-.111A8.175,8.175,0,0,0,6.513,8.65a8.825,8.825,0,0,0-.151,1.688c-.01,2.165-.009,4.367-.008,6.5v2.4A1.018,1.018,0,0,1,5.207,20.4H5.094c-.189,0-.378,0-.567.006s-.375.006-.563.006a8.74,8.74,0,0,1-1.107-.057A3.2,3.2,0,0,1,.049,17.666.526.526,0,0,0,0,17.54l0-.005V12.063a4.076,4.076,0,0,1,.664-1.538A3.3,3.3,0,0,1,3.241,9.212c.123,0,.246-.005.369-.005l.316,0,.338,0H4.4l.019-.139A10.209,10.209,0,0,1,8.9,1.737,9.794,9.794,0,0,1,14.52,0c.187,0,.377,0,.566.015a9.946,9.946,0,0,1,6.473,2.764,10.154,10.154,0,0,1,3.157,6.266l.017.134.135.006c.165.008.329.012.492.015a8.328,8.328,0,0,1,1.02.064,3.269,3.269,0,0,1,2.768,3.2c.012,1.659.012,3.193,0,4.689a3.3,3.3,0,0,1-3.262,3.25l-.38,0h-.791l-.153.532a5.622,5.622,0,0,1-5.319,4.073l-.145,0c-.163,0-.326,0-.488,0s-.34,0-.51-.008h-.036a.47.47,0,0,0-.478.326,3.287,3.287,0,0,1-3.005,1.987m-.005-4.61a1.311,1.311,0,1,0,1.311,1.313A1.313,1.313,0,0,0,14.576,22.7ZM24.788,18.42l.742,0c.116,0,.23,0,.345,0a1.3,1.3,0,0,0,1.292-1.3c.009-1.475.009-2.991,0-4.635a1.258,1.258,0,0,0-1.081-1.266,3.286,3.286,0,0,0-.442-.026c-.116,0-.232,0-.35.008s-.23.007-.345.007h-.161ZM3.554,11.188c-.151,0-.276,0-.393.013a1.277,1.277,0,0,0-1.175,1.253c-.011,1.486-.011,3.021,0,4.693A1.265,1.265,0,0,0,3.119,18.4a3.99,3.99,0,0,0,.417.019c.11,0,.221,0,.332-.005s.221-.005.333-.005h.161V11.2H4.2c-.112,0-.222,0-.331,0s-.212,0-.317,0"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Component_13_8"
        data-name="Component 13 – 8"
        xmlns="http://www.w3.org/2000/svg"
        width="29.157"
        height="27.315"
        viewBox="0 0 29.157 27.315"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_197"
              data-name="Rectangle 197"
              width="29.157"
              height="27.315"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1398" data-name="Group 1398" transform="translate(0 0)">
          <g
            id="Group_1397"
            data-name="Group 1397"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_461"
              data-name="Path 461"
              d="M14.581,27.315a3.154,3.154,0,0,1-.679-.074,3.3,3.3,0,0,1,.012-6.453,3.2,3.2,0,0,1,.659-.069,3.275,3.275,0,0,1,3,1.946.52.52,0,0,0,.509.366l.071,0c.215-.016.438-.021.653-.026a6.721,6.721,0,0,0,1.283-.112,3.542,3.542,0,0,0,2.707-3.4l0-.942c.011-2.824.023-5.744-.01-8.616A8.243,8.243,0,0,0,15.976,2.1a8.761,8.761,0,0,0-1.384-.111A8.175,8.175,0,0,0,6.513,8.65a8.825,8.825,0,0,0-.151,1.688c-.01,2.165-.009,4.367-.008,6.5v2.4A1.018,1.018,0,0,1,5.207,20.4H5.094c-.189,0-.378,0-.567.006s-.375.006-.563.006a8.74,8.74,0,0,1-1.107-.057A3.2,3.2,0,0,1,.049,17.666.526.526,0,0,0,0,17.54l0-.005V12.063a4.076,4.076,0,0,1,.664-1.538A3.3,3.3,0,0,1,3.241,9.212c.123,0,.246-.005.369-.005l.316,0,.338,0H4.4l.019-.139A10.209,10.209,0,0,1,8.9,1.737,9.794,9.794,0,0,1,14.52,0c.187,0,.377,0,.566.015a9.946,9.946,0,0,1,6.473,2.764,10.154,10.154,0,0,1,3.157,6.266l.017.134.135.006c.165.008.329.012.492.015a8.328,8.328,0,0,1,1.02.064,3.269,3.269,0,0,1,2.768,3.2c.012,1.659.012,3.193,0,4.689a3.3,3.3,0,0,1-3.262,3.25l-.38,0h-.791l-.153.532a5.622,5.622,0,0,1-5.319,4.073l-.145,0c-.163,0-.326,0-.488,0s-.34,0-.51-.008h-.036a.47.47,0,0,0-.478.326,3.287,3.287,0,0,1-3.005,1.987m-.005-4.61a1.311,1.311,0,1,0,1.311,1.313A1.313,1.313,0,0,0,14.576,22.7ZM24.788,18.42l.742,0c.116,0,.23,0,.345,0a1.3,1.3,0,0,0,1.292-1.3c.009-1.475.009-2.991,0-4.635a1.258,1.258,0,0,0-1.081-1.266,3.286,3.286,0,0,0-.442-.026c-.116,0-.232,0-.35.008s-.23.007-.345.007h-.161ZM3.554,11.188c-.151,0-.276,0-.393.013a1.277,1.277,0,0,0-1.175,1.253c-.011,1.486-.011,3.021,0,4.693A1.265,1.265,0,0,0,3.119,18.4a3.99,3.99,0,0,0,.417.019c.11,0,.221,0,.332-.005s.221-.005.333-.005h.161V11.2H4.2c-.112,0-.222,0-.331,0s-.212,0-.317,0"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
