import { useEffect } from "react";
import { useScript } from "usehooks-ts";

const useHubspotFormV2 = (data = null) => {
  const status = useScript("https://js-eu1.hsforms.net/forms/embed/v2.js", {
    removeOnUnmount: false,
  });

  const handleClick = async () => {
    const formHs = document.getElementsByClassName("js-openForm");

    Array.from(formHs).forEach((hs) => {
      const target = hs.getAttribute("data-target");
      const targetEl = document.getElementById(target);
      targetEl.style.opacity = "0";
      targetEl.style.height = "0";

      hs.addEventListener("click", (e) => {
        e.preventDefault();
        targetEl.style.opacity = "1";
        targetEl.style.height = "270px";
        targetEl.classList.add("openForm-active");
      });
    });
  };

  useEffect(() => {
    if (data) {
      handleClick();
    }

    return () => {
      handleClick();
    };
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      // @ts-ignore
      if (typeof window.hbspt !== "undefined" && data) {
        const targets = document.getElementsByClassName("js-hsform");
        for (let i = 0; i < targets.length; i++) {
          const target = targets[i];
          const targetId = `#${target.getAttribute("id")}`;

          createForm(targetId, {
            portalId: target.getAttribute("data-portal-id"),
            formId: target.getAttribute("data-form-id"),
            region: target.getAttribute("data-region"),
          });
        }
      }
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [status, data]);

  const createForm = (target, option) => {
    // @ts-ignore
    if (window.hbspt) {
      // @ts-ignore
      window.hbspt.forms.create({
        target,
        portalId: option.portalId,
        formId: option.formId,
        region: option.region,
      });
    }
  };

  return { createForm };
};

export default useHubspotFormV2;
