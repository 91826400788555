const { AsSxObject } = require("src/utils/AsSxObject");

const AlgemeneinfoStyle = AsSxObject({
  container: {
    "@media(min-width: 1020px)": {
      paddingLeft: "65px",
      paddingRight: "65px",
      marginTop: "112px",
    },
    "@media(max-width: 1020px)": {
      paddingLeft: "24px",
      paddingRight: "24px",
      marginTop: "60px",
      marginBottom: "-75px",
    },
    "@media(max-width: 600px)": {
      paddingLeft: "16px",
      paddingRight: "16px",
      marginTop: "30px",
    },
  },
  wrapper: {
    display: "flex",
    columnGap: "54px",
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
      gap: "20px",
    },
  },
  content: {
    flex: "0 0 calc(50% - (50px * 1 / 2))",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
      gap: "16px",
    },
  },
  contentH2: {
    marginBottom: "24px",
  },
  contentDescAlgemeneInfo: {
    whiteSpace: "pre-line",
    b: {
      fontFamily: "Metropolis-Bold",
    },
  },
  cta: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "24px",
    a: {
      width: "50%",
      "@media(max-width: 1020px)": {
        width: "75%",
      },
      button: {
        width: "100%",
      },
    },
  },
  image: {
    flex: "0 0 calc(50% - (50px * 1 / 2))",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  img: {
    width: "100%",
    height: "470px",
    objectFit: "cover",
    borderRadius: "20px",
    marginBlock: "30px",
  },
});

export default AlgemeneinfoStyle;
