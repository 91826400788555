import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  RadioGroup,
  Typography,
  Box,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import useStore from "src/hooks/useStore";
import { useSwiper } from "swiper/react";
import c from "./QuestionStyle";
import restructureQuestion from "./restructureQuestion";

const Question = ({ item }) => {
  const newItem = restructureQuestion(item);
  let defaultAnswer = {};
  let correctAnswer = {};

  newItem.questions.answer.forEach((el, key) => {
    defaultAnswer[key] = false;
    correctAnswer[key] = el.correct;
  });

  const [answer, setAnswer] = useState(defaultAnswer);
  const [isLoad, setIsLoad] = useState(true);
  const [isOpenAnswer, setIsOpenAnswer] = useState(false);
  const [helperText, setHelperText] = useState("");
  const store = useStore();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const param = useParams();
  const swiper = useSwiper();

  const handleRadioChange = (event) => {
    setAnswer((prev) => ({
      ...prev,
      [event.target.value]: event.target.checked,
    }));
    setHelperText(" ");
    setError(false);
  };

  const handleSubmit = (id) => (e) => {
    e.preventDefault();

    let res = true;
    for (let i = 1; i < newItem.questions.answer.length; i++) {
      if (res === true) {
        res = answer[i] === correctAnswer[i] ? true : false;
      }
    }

    if (res) {
      // dispatch(setIsAnsweredQuestionTrue(id));
      setHelperText(newItem.questions?.messageIsTrue);
      setError(false);
    } else {
      const feedback = newItem.feedback
        ? newItem.feedback
            ?.replace(/(<([^>]+)>)/gi, "")
            .replace(/(&([^>]+);)/gi, "")
        : "Sorry, fout antwoord!";
      setHelperText(feedback);

      setError(true);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoad(false);
      setHelperText(" ");
      setError(false);
    }

    return () => {
      setIsLoad(true);
      isMounted = false;
    };
  }, [param]);

  return (
    !isLoad && (
      <>
        <Box sx={c.questionWrapper}>
          {newItem.content !== null && (
            <SetInnerHtml
              sxs={{ marginBlock: "16px", paddingBlock: "30px 56px" }}
            >
              {newItem.content}
            </SetInnerHtml>
          )}
          <Typography variant="h4">{newItem.questions.question}</Typography>
          <form
            onSubmit={handleSubmit(
              newItem.questions.question.split(" ").join("-")
            )}
          >
            <FormControl sx={{ m: 3 }} error={error} variant="standard">
              <FormGroup aria-labelledby="demo-error-radios">
                {newItem.questions.answer.map((ans, i) => {
                  return (
                    <FormControlLabel
                      key={`${ans.awnser.split(" ").join("-")}-${i}`}
                      value={i}
                      control={<Checkbox />}
                      label={ans.awnser}
                      onChange={handleRadioChange}
                    />
                  );
                })}
              </FormGroup>
              <FormHelperText>{helperText}</FormHelperText>
              <FormHelperText>
                {isOpenAnswer ? "al beantwoord" : ""}
              </FormHelperText>
              {newItem.id !== null && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "fit-content", marginTop: "30px" }}
                >
                  Controleer
                </Button>
              )}
            </FormControl>
          </form>
        </Box>
        <Box sx={c.footerBack}>
          <Button
            variant="contained"
            sx={c.circleIconTrue}
            onClick={() => swiper?.slideTo(0)}
          >
            <ArrowBack />
          </Button>
        </Box>
      </>
    )
  );
};

export default Question;
