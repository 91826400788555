import { Box, Typography } from "@mui/material";
import c from "./HomeCardStyle";
/**
 *
 * @typedef {object} homacard
 *
 * @property {object} [sxs]
 * @property {string} number
 * @property {string} title
 * @property {string} variant
 * @property {any} [children]
 *
 * @param {homacard} param1
 */
const HomeCard = ({ sxs, number, title, variant, children }) => {
  return (
    <Box sx={{ ...c.box, ...sxs }}>
      {variant === "typeA" && <HeaderTyleA number={number} text={title} />}
      {variant === "typeB" && <HeaderTyleB number={number} text={title} />}
      {children}
    </Box>
  );
};

const HeaderTyleA = ({ number, text }) => {
  return (
    <Box>
      <Box
        component="div"
        sx={{ ...c.numberWrapper, ...c.typeA.numberWrapper }}>
        <Box sx={c.numberBG} />
        <Typography sx={c.number}>{number}</Typography>
      </Box>
      <Typography variant="h4" sx={c.title}>
        {text}
      </Typography>
    </Box>
  );
};
const HeaderTyleB = ({ number, text }) => {
  return (
    <Box sx={c.typeB}>
      <Box component="div" sx={c.numberWrapper}>
        <Box sx={c.numberBG} />
        <Typography sx={c.number}>{number}</Typography>
      </Box>
      <Typography variant="h4" sx={{ ...c.title, ...c.typeB.title }}>
        {text}
      </Typography>
    </Box>
  );
};

export default HomeCard;
