const { AsSxObject } = require("src/utils/AsSxObject");

const FooterBoxStyle = AsSxObject({
  item: {
    height: "100%",
    width: "100%",
    color: "#ffffff",
    "@media(max-width: 1020px)": {
      flex: "0 0 calc(33.333% - (20px * 2 / 3))",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 calc(50% - (20px * 1 / 2))",
    },
  },
  itemIcon: {
    "@media(max-width: 1020px)": {
      order: "1",
      width: "auto",
    },
    "@media(max-width: 600px)": {
      order: "9",
    },
  },
  fullwidth: {
    gridColumn: "span 5",
    marginTop: "70px",
    "@media(max-width: 1020px)": {
      display: "none",
    },
  },
  title: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "600",
    marginBottom: "30px",
    "@media(max-width: 1020px)": {
      marginBottom: "5px",
    },
  },
  spesialTitle: {
    display: "block",
    "@media(min-width: 1021px)": {
      display: "none",
    },
  },
  subtitle: {
    fontWeight: "600",
    lineHeight: "28px",
  },
  text: {
    lineHeight: "28px",
    whiteSpace: "pre-line",
    fontFamily: "Metropolis-Regular",
    "@media(min-width: 1281px)": {
      whiteSpace: "inherit",
    },
  },
  logo: {
    gridColumn: "span 2",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
      padding: "20px",
    },
  },
  logoImg: {
    objectFit: "contain",
    width: "100%",
    maxWidth: "320px",
  },
});

export default FooterBoxStyle;
