const { AsSxObject } = require("src/utils/AsSxObject");

const ItemsStyle = AsSxObject({
  wrapper: {
    marginBlock: "60px",
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    gridAutoRows: "minmax(265px,auto)",
    gridAutoFlow: "dense",
    gap: "60px",
    "@media(max-width: 1025px)": {
      gap: "30px",
      gridAutoRows: "minmax(100px,auto)",
    },
    "@media(max-width: 600px)": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
  },
  item1: {
    gridColumnEnd: "span 3",
    gridRowEnd: "span 2",
    "@media(max-width: 1025px)": {
      gridColumnEnd: "span 3",
      gridRowEnd: "span 4",
    },
  },
  item2: {
    gridColumnEnd: "span 4",
    gridRowEnd: "span 1",
    "@media(max-width: 1367px)": {
      gridColumnEnd: "span 3",
    },
    "@media(max-width: 1025px)": {
      gridColumnEnd: "span 7",
      gridRowEnd: "span 1",
    },
  },
  item3: {
    gridColumnEnd: "span 3",
    gridRowEnd: "span 1",
    "@media(max-width: 1367px)": {
      gridColumnEnd: "span 4",
    },
    "@media(max-width: 1025px)": {
      gridColumnEnd: "span 7",
      gridRowEnd: "span 1",
    },
  },
  item4: {
    gridColumnEnd: "span 3",
    gridRowEnd: "span 1",
    "@media(max-width: 1367px)": {
      gridColumnEnd: "span 4",
    },
    "@media(max-width: 1025px)": {
      gridColumnEnd: "span 7",
      gridRowEnd: "span 1",
    },
  },
  item5: {
    gridColumnEnd: "span 4",
    gridRowEnd: "span 1",
    "@media(max-width: 1367px)": {
      gridColumnEnd: "span 3",
    },
    "@media(max-width: 1025px)": {
      gridColumnEnd: "span 7",
      gridRowEnd: "span 1",
    },
  },
});

export default ItemsStyle;
