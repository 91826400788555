import { Box, Typography } from "@mui/material";
import c from "./IntroStyle";

const Intro = () => {
  return (
    <Box sx={c.wrapper} className="Intro">
      <Box sx={c.content}>
        <Typography sx={c.title} variant="h2">
          Hallo rijinstructeur in spe!
        </Typography>
        <Typography variant="body1">
          Welkom op het dashboard van Platform Rijschoollesgever. Hier vind je
          alle informatie om jouw opleiding tot rijschoollesgever te
          vervolledigen. Dit platform bundelt alle vakliteratuur, hand- boeken,
          slides, lesfiches … die je nodig hebt om te slagen voor jouw
          schriftelijk en mondeling examen.
        </Typography>
      </Box>
      <Box
        sx={c.image}
        component={"img"}
        alt="Images Intro"
        src="/images/dummy/IMG_5600.png"
      />
    </Box>
  );
};

export default Intro;
