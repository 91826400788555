const { AsSxObject } = require("src/utils/AsSxObject");

const FooterStyle = AsSxObject({
  wrapper: {
    backgroundColor: (t) => t.palette.primary.dark,
    marginInline: "auto",
  },
  wrapperWhite: {
    marginInline: "auto",
    paddingBlock: "0 60px",
  },
  container: {
    // backgroundColor: (t) => t.palette.primary.dark,
    color: "#ffffff",
    marginInline: "auto",
    display: "block",
    padding: "72px",
    paddingBottom: "46px",
    maxWidth: "1525px",
    "@media(max-width: 1020px)": {
      padding: "30px",
      paddingTop: "50px",
      paddingBottom: "40px",
    },
    "@media(max-width: 600px)": {
      padding: "20px",
      paddingBottom: "30px",
    },
  },
  items: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    columnGap: "30px",
    "@media(max-width: 1530px)": {
      columnGap: "20px",
    },
    "@media(max-width: 1020px)": {
      display: "flex",
      flexWrap: "wrap",
      columnGap: "20px",
      rowGap: "30px",
    },
  },
  divider: {
    borderBottomColor: "currentColor",
    marginTop: "75px",
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "30px",
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "10px",
    },
  },
  privacy_policy: {
    "@media(min-width: 600px)": {
      marginRight: "auto",
      paddingInline: "30px",
    },
  },
});

export default FooterStyle;
