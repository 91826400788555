// @ts-nocheck
import { Helmet } from "react-helmet-async";
import AuthFooter from "src/components/auth/AuthFooter";
import RegisterComponent from "src/components/auth/Register";

export default function Register() {
  return (
    <>
      <Helmet>
        <title>Registreer </title>
        <meta
          name="description"
          content="Maak een account aan op Platform rijschoollesgever en krijg toegang tot al het lesmateriaal om te slagen als rijschoollesgever."
        />
      </Helmet>
      <RegisterComponent />
      <AuthFooter />
    </>
  );
}
