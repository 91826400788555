import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const ColumnStyle = AsSxObject({
  cardFooter: {
    marginTop: "52px",
    paddingInline: "0",
    flex: "auto",
    display: "flex",
    alignItems: "flex-end",
    whiteSpace: "pre-line",
    "@media(max-width: 600px)": {
      marginTop: "16px",
    },
  },
  cardBody: {
    whiteSpace: "pre-line",
  },
  cardTitleMobile: {
    color: g.color.primary,
    marginBottom: "16px",
    "@media(min-width: 600px)": {
      display: "none",
    },
  },
  cardBodyTitle: {
    marginBottom: "40px",
    whiteSpace: "pre-line",
    "@media(max-width: 600px)": {
      marginBottom: "18px",
    },
  },
});

export default ColumnStyle;
