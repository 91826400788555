/**
 *
 * @param {string} email
 * @param {string} bcc
 * @param {string} subject
 * @param {*} body
 */
const useMailto = (email, subject, body, bcc = "") => {
  let bodyMsg = [];
  Object.entries(body).forEach((item) => {
    bodyMsg.push(`${item[0]}: ${item[1]}`);
  });

  body = bodyMsg.join(" %0D");

  const withBcc = bcc !== "" ? `&bcc=${bcc}` : "";

  window.location.href = `mailto:${email}?body=${body}&subject=${subject}${withBcc}`;
};

export default useMailto;
