import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Documentation
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Documentation} param1
 */

export default function DocumentationIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1375"
        data-name="Group 1375"
        xmlns="http://www.w3.org/2000/svg"
        width="25.844"
        height="20.995"
        viewBox="0 0 25.844 20.995"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_194"
              data-name="Rectangle 194"
              width="25.844"
              height="20.995"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Group_1374"
          data-name="Group 1374"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_445"
            data-name="Path 445"
            d="M0,1.3A1.871,1.871,0,0,1,.515.393,1.629,1.629,0,0,1,1.633,0Q3.527,0,5.422,0a.791.791,0,0,1,.887.891c0,.109,0,.219.008.328a.7.7,0,0,0,.533.632,1.814,1.814,0,0,0,.425.039c4.328,0,8.656.009,12.985,0a1.538,1.538,0,0,1,1.619,1.194,9.964,9.964,0,0,1,.074,1.162H2.922c-.644,0-.879.2-1.006.816Q.987,9.583.053,14.1A.8.8,0,0,1,0,14.238V1.3"
            transform="translate(0 0)"
          />
          <path
            id="Path_446"
            data-name="Path 446"
            d="M4.86,31.436H25.746a1.478,1.478,0,0,1,1.583,1.036,3.411,3.411,0,0,1,.031,1.194c-.218,2.1-.457,4.2-.691,6.3-.2,1.757-.387,3.514-.592,5.27a1.52,1.52,0,0,1-1.584,1.447c-.067,0-.135,0-.2,0H2.913c-.076,0-.152,0-.227,0a.767.767,0,0,1-.763-.98c.286-1.409.58-2.817.87-4.226q1-4.857,2-9.713c.02-.1.043-.2.071-.327"
            transform="translate(-1.553 -25.689)"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_993"
        data-name="Group 993"
        xmlns="http://www.w3.org/2000/svg"
        width="25.517"
        height="21.083"
        viewBox="0 0 25.517 21.083"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_47"
              data-name="Rectangle 47"
              width="25.517"
              height="21.083"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_992" data-name="Group 992" clipPath="url(#clip-path)">
          <path
            id="Path_17"
            data-name="Path 17"
            d="M25.512,5.607a.937.937,0,0,0-.986-.657h-.039c-.294.006-.61.009-.993.009l-.764,0-.665,0c-.057,0-.113,0-.175-.007h-.013v-.64c0-.555,0-1.1,0-1.649,0-.739-.29-1.027-1.031-1.027H8.4s0-.485,0-.644C8.394.3,8.087,0,7.4,0H1.014C.3,0,0,.3,0,1.021V12.434q0,3.827,0,7.655a.868.868,0,0,0,1,.993H22.943a.868.868,0,0,0,.979-.805c.041-.341.078-.682.115-1.023l.245-2.242q.2-1.861.408-3.722.314-2.853.632-5.706c.04-.352.086-.71.131-1.055q.031-.242.062-.485V5.622ZM20.2,4.955H4.073c-.748,0-1,.208-1.15.966q-.529,2.59-1.064,5.18l-.18.875V1.679H6.731v.132c0,.166,0,.334,0,.5a.865.865,0,0,0,.982.984H20.2Zm3.557,1.679-1.41,12.759H1.859L4.477,6.634Z"
            transform="translate(0 0.001)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
