import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const FooterStyle = AsSxObject({
  wrapper: {
    display: "flex",
    justyfyContent: "flex-start",
    width: "100%",
    columnGap: "72px",
    "@media(max-width: 600px)": {
      paddingInline: "24px",
      columnGap: "30px",
      position: "relative",
    },
    "@media(max-width: 1020px)": {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  item: {
    fontFamily: "Metropolis-regular",
    fontSize: "12px",
    color: g.color.black,
    flex: "0 0 none",
    "&:last-child": {
      flex: "auto",
      marginLeft: "auto",
      display: "flex",
      justifyContent: "flex-end",
      "@media(max-width: 1020px)": {
        flex: "0 0 100%",
        justifyContent: "center",
      },
    },
  },
});

export default FooterStyle;
