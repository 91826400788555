import Footer from "src/components/frontend/Footer";
import Navbar from "src/components/frontend/Navbar";
import Title from "src/components/frontend/Title";
import BlogSingleComponent from "src/components/frontend/Blogs/BlogSingle";

const BlogSingle = () => {
  return (
    <>
      <Navbar />
      <Title text="Ons nieuws" />
      <BlogSingleComponent />
      <Footer />
    </>
  );
};

export default BlogSingle;
