import Sidebar from "src/components/moleculs/Sidebar";
import LessonPackagesComponent from "src/components/backend/LessonPackages";

export default function LessonPackages() {
  return (
    <>
      <Sidebar />
      <LessonPackagesComponent />
    </>
  );
}
