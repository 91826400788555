import { Box, Button, Typography } from "@mui/material";
import c from "./CardStyle";
import { Link } from "react-router-dom";
import ArrowRight from "src/components/atoms/icons/Svg/ArrowRight";
import IcLocation from "src/components/atoms/icons/Svg/IcLocation";

/**
 * @typedef {object} TCard
 * @property {string} slug
 * @property {string} title
 * @property {string} desc
 * @property {string} location
 *
 * @param {TCard} param0
 * @returns
 */
const Card = ({ slug, title, desc, location }) => {
  return (
    <Box sx={c.wrapper}>
      <Box sx={c.title}>
        <Typography sx={c.title__label} variant="h4" component={"span"}>
          L
        </Typography>
        <Typography sx={c.title__text} variant="h4" component={"span"}>
          {title}
        </Typography>
      </Box>
      <Typography variant="body1" sx={c.content__text}>
        {desc}
      </Typography>
      <Box sx={c.footer}>
        <Box sx={{ ...c.footer__button, ...c.footer__button__location }}>
          <IcLocation /> {location}
        </Box>
        <Link to={`/vacatures/${slug}`}>
          <Button sx={c.footer__button}>
            Lees Meer <ArrowRight />
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Card;
