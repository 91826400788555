import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const AccordionListStyle = AsSxObject({
  wrapper: {
    display: "flex",
    padding: "36px 20px",
    borderBottom: "4px solid",
    borderBottomColor: g.color.primary,
    justifyContent: "space-between",
    position: "relative",
  },
  accordion: {
    boxShadow: "none",
    width: "100%",
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    paddingInline: "0",
    minHeight: "0",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
      marginBlock: "0",
    },
  },
  icon: {
    height: "32px",
    width: "32px",
  },
  iconDown: {
    transform: "rotate(90deg)",
  },
  text: {
    display: "flex",
    alignItems: "center",
  },
  desc: {
    whiteSpace: "break-spaces",
  },
});
export default AccordionListStyle;
