import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Faq
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Faq} param1
 */

export default function FaqIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1373"
        data-name="Group 1373"
        xmlns="http://www.w3.org/2000/svg"
        width="24.368"
        height="24.347"
        viewBox="0 0 24.368 24.347"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_193"
              data-name="Rectangle 193"
              width="24.368"
              height="24.347"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1372" data-name="Group 1372" clipPath="url(#clip-path)">
          <path
            id="Path_444"
            data-name="Path 444"
            d="M23.7,8.331C21.987,3.447,18.457.668,13.205.072A11.1,11.1,0,0,0,6.433,1.465,12.011,12.011,0,0,0,.19,10.1c-.058.309-.1.622-.134.925-.017.138-.034.276-.053.414l0,.023v1.388l0,.025c.026.181.05.362.073.544.052.4.106.817.188,1.226A11.4,11.4,0,0,0,1.879,18.6c.038.058.041.063.008.141q-.726,1.71-1.438,3.426L0,23.241v.373l.03.067a1.067,1.067,0,0,0,.745.657l.038.009h.242l.159-.061c.076-.029.152-.058.228-.09l.917-.382c1.071-.446,2.142-.892,3.21-1.346a.29.29,0,0,1,.1-.028.219.219,0,0,1,.112.045A12.048,12.048,0,0,0,12.1,24.332h0a12.6,12.6,0,0,0,3.351-.469,12.2,12.2,0,0,0,7.533-6.087A11.774,11.774,0,0,0,23.7,8.331M12.136,18.758h-.007a.968.968,0,0,1-.006-1.936.98.98,0,0,1,.7.292.955.955,0,0,1,.279.689.976.976,0,0,1-.964.955m.955-5.347c0,.407,0,.814,0,1.221v.488a.922.922,0,0,1-.558.882.911.911,0,0,1-.379.086.761.761,0,0,1-.576-.259,1.169,1.169,0,0,1-.3-.66c-.022-.315-.018-.629-.014-.933,0-.12,0-.241,0-.361v-.334c0-.29,0-.58,0-.87a.925.925,0,0,1,.945-.985A2.106,2.106,0,0,0,14.27,9.464a1.965,1.965,0,0,0-1.221-1.787,2.127,2.127,0,0,0-.9-.213A2.169,2.169,0,0,0,10.07,9.525a.915.915,0,0,1-.916.92l-.072,0a.927.927,0,0,1-.838-1.034A3.86,3.86,0,0,1,11.333,5.73a4.355,4.355,0,0,1,.92-.1A3.9,3.9,0,0,1,16.175,9.2a3.944,3.944,0,0,1-3.084,4.214"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1040"
        data-name="Group 1040"
        xmlns="http://www.w3.org/2000/svg"
        width="24.368"
        height="24.347"
        viewBox="0 0 24.368 24.347"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_163"
              data-name="Rectangle 163"
              width="24.368"
              height="24.347"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1039" data-name="Group 1039" clipPath="url(#clip-path)">
          <path
            id="Path_95"
            data-name="Path 95"
            d="M23.7,8.33C21.986,3.447,18.456.668,13.205.071A11.094,11.094,0,0,0,6.432,1.464,12.012,12.012,0,0,0,.19,10.1c-.058.309-.1.622-.134.925-.017.138-.034.276-.053.414l0,.023v1.388l0,.024c.026.181.05.362.073.544.052.4.106.817.188,1.226A11.4,11.4,0,0,0,1.879,18.6c.038.058.041.063.008.141q-.726,1.71-1.438,3.426L0,23.24v.373l.03.067a1.067,1.067,0,0,0,.746.657l.038.009h.242l.159-.061c.076-.029.152-.058.228-.09l.918-.382c1.071-.446,2.142-.892,3.21-1.346a.291.291,0,0,1,.1-.028.219.219,0,0,1,.112.045A12.048,12.048,0,0,0,12.1,24.331h0a12.6,12.6,0,0,0,3.351-.469,12.2,12.2,0,0,0,7.532-6.087A11.775,11.775,0,0,0,23.7,8.33M6.808,20.977l-.029-.017a.388.388,0,0,1-.037-.023,1.532,1.532,0,0,0-1.027-.418,1.989,1.989,0,0,0-.875.23c-.623.3-1.281.577-1.917.841l-.3.124.083-.2c.344-.826.67-1.605,1.012-2.392a1.177,1.177,0,0,0-.113-1.236,9.726,9.726,0,0,1-1.631-4.059,10.1,10.1,0,0,1,1.673-7.5,9.927,9.927,0,0,1,6.389-4.271,11.477,11.477,0,0,1,2.275-.236,10.007,10.007,0,0,1,6.545,2.464,9.932,9.932,0,0,1,3.534,6.28,10.3,10.3,0,0,1-5.815,10.963,10.159,10.159,0,0,1-4.368.985c-.227,0-.458-.007-.687-.022a10.23,10.23,0,0,1-4.715-1.512"
            transform="translate(0 0.001)"
          />
          <path
            id="Path_96"
            data-name="Path 96"
            d="M45.513,29.85a1.965,1.965,0,0,1,1.221,1.786,2.106,2.106,0,0,1-2.059,2.223.925.925,0,0,0-.945.984c0,.29,0,.58,0,.87v.334c0,.12,0,.241,0,.361,0,.3-.008.618.014.933a1.17,1.17,0,0,0,.3.66.762.762,0,0,0,.576.258A.907.907,0,0,0,45,38.174a.922.922,0,0,0,.558-.882V36.8c0-.407,0-.814,0-1.221a3.943,3.943,0,0,0,3.084-4.213A3.9,3.9,0,0,0,44.717,27.8a4.355,4.355,0,0,0-.92.1,3.859,3.859,0,0,0-3.088,3.679.926.926,0,0,0,.838,1.034l.072,0a.915.915,0,0,0,.916-.92,2.169,2.169,0,0,1,2.08-2.061,2.129,2.129,0,0,1,.9.213"
            transform="translate(-32.464 -22.173)"
          />
          <path
            id="Path_97"
            data-name="Path 97"
            d="M57.075,84.058a.955.955,0,0,0-.279-.689.981.981,0,0,0-.7-.292.956.956,0,0,0-.685.29.971.971,0,0,0,.69,1.646h.007a.976.976,0,0,0,.964-.955"
            transform="translate(-43.975 -66.256)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
