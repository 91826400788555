import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Youtube
 * @property {object} [sxs]
 *
 * @param {Youtube} param1
 */

export default function Youtube({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.4216 7.7191C24.149 6.69262 23.3458 5.8842 22.326 5.60985C20.4774 5.11133 13.0651 5.11133 13.0651 5.11133C13.0651 5.11133 5.65274 5.11133 3.80417 5.60985C2.78433 5.88424 1.98112 6.69262 1.70851 7.7191C1.2132 9.57964 1.2132 13.4615 1.2132 13.4615C1.2132 13.4615 1.2132 17.3434 1.70851 19.2039C1.98112 20.2304 2.78433 21.0051 3.80417 21.2795C5.65274 21.778 13.0651 21.778 13.0651 21.778C13.0651 21.778 20.4774 21.778 22.326 21.2795C23.3458 21.0051 24.149 20.2304 24.4216 19.2039C24.9169 17.3434 24.9169 13.4615 24.9169 13.4615C24.9169 13.4615 24.9169 9.57964 24.4216 7.7191ZM10.6408 16.9859V9.93707L16.8361 13.4616L10.6408 16.9859Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
