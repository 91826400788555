import { Edit } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import c from "./TitleMainStyle";

/**
 * @typedef {object} TitleMain
 * @property {object} [sxs]
 * @property {string} text
 * @property {string} [link]
 * @property {string} [to]
 *
 * @param {TitleMain} param1
 */

const TitleMain = ({ sxs, text, link, to }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs }}>
      <Typography variant="h2">{text}</Typography>
      {link && (
        <Link to={to}>
          <Typography sx={c.link} variant="body1">
            {text}
            <Edit />
          </Typography>
        </Link>
      )}
    </Box>
  );
};

export default TitleMain;
