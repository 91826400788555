import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./_rootReducer";
import { createStore } from "@reduxjs/toolkit/dist";

const persistConfig = {
  key: "root",
  storage,
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  let _store = createStore(
    persistedReducer,
    // @ts-ignore
    window?.__REDUX_DEVTOOLS_EXTENSION__?.()
  );
  let persistor = persistStore(_store);
  return { store: _store, persistor };
};

export default configureStore;
