import { useSelector } from "react-redux";

const useStore = () => {
  /**
   * @type {StoreRootType}
   */
  const store = useSelector((state) => state);
  return store;
};

export default useStore;
