import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Cursus
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Cursus} param1
 */

export default function CursusIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_985"
        data-name="Group 985"
        xmlns="http://www.w3.org/2000/svg"
        width="28.533"
        height="21.784"
        viewBox="0 0 28.533 21.784"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_43"
              data-name="Rectangle 43"
              width="28.533"
              height="21.784"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Group_984"
          data-name="Group 984"
          transform="translate(0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_6"
            data-name="Path 6"
            d="M0,30.049c.368-.739.7-.885,1.764-.763v.345q0,6.114,0,12.228a2.742,2.742,0,0,0,.578,1.815,2.649,2.649,0,0,0,1.95.983c.943.071,1.892.12,2.821.279a9.533,9.533,0,0,1,4.4,1.889c.158.125.308.262.507.432-.606-.1-1.145-.214-1.688-.289a19.414,19.414,0,0,0-4.832-.1c-1.389.156-2.77.4-4.152.615A1.1,1.1,0,0,1,0,46.712Z"
            transform="translate(0 -25.729)"
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M37.924,20.686c-.075-.06-.126-.093-.169-.135a10.969,10.969,0,0,0-6.2-2.957c-.868-.134-1.752-.177-2.63-.235a1.065,1.065,0,0,1-1.123-1.17q0-7.52,0-15.041A1.05,1.05,0,0,1,29.017,0a14.062,14.062,0,0,1,4.211.691,8.28,8.28,0,0,1,4.449,3.345,1.527,1.527,0,0,1,.249.774q.021,7.855.01,15.71c0,.043-.006.086-.013.166"
            transform="translate(-24.458 0)"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M125.008,20.677v-.355c0-5,.015-10.009-.016-15.013a2.78,2.78,0,0,1,.7-1.867A8.914,8.914,0,0,1,131.1.337a25.3,25.3,0,0,1,2.927-.319,1.013,1.013,0,0,1,1.112,1.132q0,4.248,0,8.5,0,3.245,0,6.49c0,.834-.38,1.219-1.223,1.235a14.989,14.989,0,0,0-5.2.946,10.116,10.116,0,0,0-3.479,2.2c-.046.045-.093.09-.142.133-.012.01-.033.011-.089.027"
            transform="translate(-109.954 -0.012)"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M136.953,47.36c.539-.378,1.008-.751,1.518-1.056a10.432,10.432,0,0,1,4.066-1.341c.726-.095,1.46-.124,2.192-.172a2.58,2.58,0,0,0,2.429-1.98,3.476,3.476,0,0,0,.082-.856q.007-6.087,0-12.175v-.365a7.624,7.624,0,0,1,.945.007A.989.989,0,0,1,149,30.3a1.614,1.614,0,0,1,.008.195q0,8.024,0,16.048a1.023,1.023,0,0,1-1.272,1.086c-.128-.019-.255-.049-.382-.077a26.09,26.09,0,0,0-7.415-.578c-.968.07-1.928.246-2.985.386"
            transform="translate(-120.475 -25.865)"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_987"
        data-name="Group 987"
        xmlns="http://www.w3.org/2000/svg"
        width="28.533"
        height="23.075"
        viewBox="0 0 28.533 23.075"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_44"
              data-name="Rectangle 44"
              width="28.533"
              height="23.075"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Group_986"
          data-name="Group 986"
          transform="translate(0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_10"
            data-name="Path 10"
            d="M28.533,3.851v18.5c-.368.66-.716.841-1.339.653a24.683,24.683,0,0,0-5.985-1.061,14.327,14.327,0,0,0-6.457,1.014,1.146,1.146,0,0,1-.967,0A13.476,13.476,0,0,0,8.33,21.917a24.007,24.007,0,0,0-6.935,1.065,1.457,1.457,0,0,1-.624.066A.953.953,0,0,1,0,21.981Q0,13.093,0,4.2c0-.046,0-.093,0-.139a.952.952,0,0,1,.947-.938c.371-.01.743,0,1.115,0h.893c0-.7,0-1.359,0-2.017a.938.938,0,0,1,.879-1A14.151,14.151,0,0,1,8.38.288a10.006,10.006,0,0,1,5.213,2.635c.229.227.441.471.673.722.074-.077.14-.14.2-.208A9.251,9.251,0,0,1,18.8.638,14.286,14.286,0,0,1,24.473.07c.809.071,1.1.407,1.105,1.229,0,.6,0,1.2,0,1.826.6,0,1.162.023,1.724-.006a1.123,1.123,0,0,1,1.231.733M13.3,19.362c.008-.091.013-.119.013-.146q0-6.7,0-13.4a.622.622,0,0,0-.093-.308,6.889,6.889,0,0,0-3.193-2.714,11.566,11.566,0,0,0-4.642-.873c-.163,0-.326,0-.487,0V16.74c3.085-.038,5.963.529,8.4,2.622M23.645,1.955c-.083-.008-.155-.02-.228-.021a12.439,12.439,0,0,0-3.823.471,7.364,7.364,0,0,0-3.873,2.525c-.214.277-.5.529-.5.931q0,6.628,0,13.257c0,.068.013.135.024.235,2.439-2.09,5.322-2.648,8.4-2.617ZM26.6,20.834V5.054H25.578v.352q0,6.06,0,12.119c0,.111,0,.224-.007.334a.944.944,0,0,1-.973.877c-.13,0-.26-.008-.389-.019a12.287,12.287,0,0,0-5.079.576,6.928,6.928,0,0,0-1.852.936,23.6,23.6,0,0,1,9.324.6m-15.347-.605c-.114-.081-.164-.121-.218-.154a9.137,9.137,0,0,0-4.259-1.331c-.9-.065-1.816-.017-2.724-.006a1.064,1.064,0,0,1-.753-.221,1.093,1.093,0,0,1-.347-.9q.005-6.114,0-12.229v-.33H1.928V20.834a23.659,23.659,0,0,1,9.326-.605"
            transform="translate(0 -0.001)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
