const IS_LOCAL = false;
export const MAIN_DOMAIN = IS_LOCAL
  ? "http://platform-rijschoollesgever-api.test"
  : "https://api.platform-rijschoollesgever.be";

export const WP_DOMAIN = IS_LOCAL
  ? "http://platform-wp.test"
  : "https://admin.platform-rijschoollesgever.be/";

// export const MAIN_DOMAIN = IS_LOCAL
//   ? "http://platform-rijschoollesgever-api.test"
//   : "https://phplaravel-881441-3423316.cloudwaysapps.com";

// export const WP_DOMAIN = IS_LOCAL
//   ? "http://platform-wp.test"
// : "https://wordpress-881441-3423264.cloudwaysapps.com/";

// export const WP_DOMAIN = IS_LOCAL
//   ? "http://platform-wp.test"
//   : "https://wordpress-881441-3423264.cloudwaysapps.com/";

export const URI = {
  submitCandidate: `${WP_DOMAIN}/wp-json/cb/v1/submit-candidate`,
};
