import React from "react";
import Footer from "src/components/frontend/Footer";
import Navbar from "src/components/frontend/Navbar";
import Title from "src/components/frontend/Title";
import StaticContent from "src/components/moleculs/StaticContent";

const Success = () => {
  return (
    <>
      <Navbar />
      <Title text="Betaling Succesvol" />
      <StaticContent>
        <p>Bedankt voor uw betaling! Uw pakket is succesvol gekocht.</p>
        <p>
          We nemen zo spoedig mogelijk contact met u op om verdere stappen te bespreken.
        </p>
        {/* Add any additional message or instructions here */}
      </StaticContent>
      <Footer />
    </>
  );
};

export default Success;
