import { AsSxObject } from "src/utils/AsSxObject";

const Styles = AsSxObject({
  wrapper: {
    marginBlock: "120px",
  },
  head: {
    background: "#F4F6FF",
    border: "1px solid #7084E4",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    padding: "40px 32px",
  },
  head__wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
      gap: "16px",
    },
  },
  head__title: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  head__title__label: {
    width: "60px",
    height: "60px",
    backgroundColor: (t) => t.palette.primary.main,
    color: "white",
    borderRadius: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width: 600px)": {
      width: "45px",
      height: "45px",
    },
  },
  head__title__text: {},
  head__button: {},
  head__line: {
    width: "100%",
    height: "1px",
    backgroundColor: "#D4DAF7",
    marginBlock: "24px",
  },
  head__attrs: {
    display: "flex",
    "@media(max-width: 1024px)": {
      flexWrap: "wrap",
      rowGap: "16px",
    },
  },
  head__attr: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    "@media(max-width: 1024px)": {
      flex: "0 0 50%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  head__attr__wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  head__attr__icon: {
    minWidth: "40px",
  },
  head__attr__title: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2F8F",
  },
  head__attr__value: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7084E4",
  },
  body: {
    marginBlock: "60px",
  },
  body__button: {
    marginTop: "40px",
    marginInline: "24px",
  },
});

export default Styles;
