import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const CardStyle = AsSxObject({
  wrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: g.color.primary,
    borderRadius: "20px",
    padding: "32px 16px 32px 32px",
    color: g.color.white,
    position: "relative",
    display: "flex",
  },
  content: {
    marginTop: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    height: "50px",
    width: "50px",
  },
});

export default CardStyle;
