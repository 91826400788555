import { AsSxObject } from "src/utils/AsSxObject";

const CardStyles = AsSxObject({
  wrapper: {
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.11)",
    borderRadius: "20px",
    background: "white",
    overflow: "hidden",
    height: "100%",
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: 248,
  },
  content: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  content__term: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingBottom: "4px",
    gap: "16px",
  },
  content__term__cat: {
    color: (t) => t.palette.primary.dark,
    background: "#D4DAF7",
    padding: "8px 16px",
    borderRadius: "20px",
  },
  content__term__date: {},
  content__title: {
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  content__shortdesc: {
    lineHeight: "1.2em",
    color: "#5F6D7E",
  },
  content__button: {
    padding: "8px",
    fontFamily: "Metropolis-Bold",
    fontSize: "16px",
    lineHeight: "1.2em",
    fontStyle: "normal",
    color: (t) => t.palette.primary.main,
    "&:hover": {
      boxShadow: "none",
    },
    svg: {
      marginLeft: "8px",
    },
  },
});

export default CardStyles;
