import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const profileAccountSlice = createSlice({
  name: "profileAccount",
  initialState,
  reducers: {
    setData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setData } = profileAccountSlice.actions;

export default profileAccountSlice.reducer;
