const { AsSxObject } = require("src/utils/AsSxObject");

const RenderedVideoStyle = AsSxObject({
  videoWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    marginBottom: "20px",
  },
  videoSource: {
    width: "100% !important",
    height: "auto !important",
    display: "block",
  },
});

export default RenderedVideoStyle;
