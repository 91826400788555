import { AsSxObject } from "src/utils/AsSxObject";

const BlogsStyles = AsSxObject({
  top__wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "60px",
    "@media(max-width: 1024px)": {
      flexDirection: "column",
      gap: "24px",
    },
  },
  top__col: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "@media(max-width: 600px)": {
      flexDirection: "column",
    },
  },
  top__listscat: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    maxWidth: "600px",
    overflow: "auto",
    paddingBottom: "8px",
    transform: "translateY(2px)",
    "@media(max-width: 600px)": {
      maxWidth: "calc(100vw - 32px)",
    },
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#2946d67a",
    },
  },
  top__input: {
    maxWidth: "350px",
    display: "flex",
    alignItems: "center",
    border: "solid 1px #2946d6",
    borderRadius: "60px",
    padding: "4px 6px",

    "> svg": {
      marginInline: "6px",
      transform: "translateY(2px)",
    },
    "> div": {
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
    "> button": {
      paddingBlock: "8px",
      whiteSpace: "nowrap",
    },
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "130px",
  },
  button: {
    flex: "0 0 100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  item: {
    flex: "0 0 33.3333%",
    padding: "16px",
    "@media(max-width: 1024px)": {
      flex: "0 0 50%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
});

export default BlogsStyles;
