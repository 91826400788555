const { AsSxObject } = require("src/utils/AsSxObject");

const LessonPackagesStyle = AsSxObject({
  title: {
    marginBottom: "112px",
    "@media(max-width: 600px)": {
      marginBottom: "60px",
    },
    "@media(max-width: 1020px)": {
      marginBottom: "60px",
    },
  },
  wrapperList: {
    maxWidth: "856px",
    marginInline: "auto",
    display: "flex",
    flexDirection: "column",
    rowGap: "82px",
    marginBottom: "30px",
  },
  wrapperButton: {
    justifyContent: "center",
    display: "flex",
    marginBottom: "30px",
    gap: "42px",
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
      gap: "16px",
    },
  },
});

export default LessonPackagesStyle;
