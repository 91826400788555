import { ChevronRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import c from "./CardStyle";

/**
 * @property {string} to
 * @property {string} title
 * @property {object} [sxs]
 *
 * @param {object} param1
 */

const Card = ({ to, title, sxs }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs }}>
      <Box sx={c.content}>
        <Typography variant="h3">{title}</Typography>
        <ChevronRight sx={c.icon} />
      </Box>
      <Link to={to} className="streched-link"></Link>
    </Box>
  );
};

export default Card;
