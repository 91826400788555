import { Box, Button, Container, Typography } from "@mui/material";
import c from "./SingleStyle";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import IcLocationLabel from "src/components/atoms/icons/Svg/IcLocationLabel";
import IcEducationLabel from "src/components/atoms/icons/Svg/IcEducationLabel";
import IcSalaryLabel from "src/components/atoms/icons/Svg/IcSalaryLabel";
import IcWorkHourLabel from "src/components/atoms/icons/Svg/IcWorkHourLabel";
import useMailto from "src/hooks/useMailto";
import FormPopup from "./FromPopup/FormPopup";

const Single = () => {
  const { slug } = useParams();
  const [isPopup, setIsPopup] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`api/vacatures/${slug}`).then((res) => {
      setData(res.data);
    });
  }, [slug]);

  return (
    <Box sx={c.wrapper}>
      <Container>
        <Box sx={c.head}>
          <Box sx={c.head__wrapper}>
            <Box sx={c.head__title}>
              <Typography
                sx={c.head__title__label}
                variant="h3"
                component={"span"}
              >
                L
              </Typography>
              <Typography
                sx={c.head__title__text}
                variant="h3"
                component={"span"}
              >
                {data?.data.title}
              </Typography>
            </Box>
            <Button
              onClick={() => setIsPopup(true)}
              sx={c.head__button}
              variant="contained"
            >
              Solliciteren
            </Button>
          </Box>
          <Box sx={c.head__line} />
          <Box sx={c.head__attrs}>
            <Box sx={c.head__attr}>
              <Box sx={c.head__attr__icon}>
                <IcLocationLabel />
              </Box>
              <Box sx={c.head__attr__wrapper}>
                <Typography sx={c.head__attr__title} variant="body1">
                  Locatie
                </Typography>
                <Typography sx={c.head__attr__value} variant="body1">
                  {data?.data.meta.location}
                </Typography>
              </Box>
            </Box>
            <Box sx={c.head__attr}>
              <Box sx={c.head__attr__icon}>
                <IcEducationLabel />
              </Box>
              <Box sx={c.head__attr__wrapper}>
                <Typography sx={c.head__attr__title} variant="body1">
                  Opleidingsniveau
                </Typography>
                <Typography sx={c.head__attr__value} variant="body1">
                  {data?.data.meta.education}
                </Typography>
              </Box>
            </Box>
            <Box sx={c.head__attr}>
              <Box sx={c.head__attr__icon}>
                <IcSalaryLabel />
              </Box>
              <Box sx={c.head__attr__wrapper}>
                <Typography sx={c.head__attr__title} variant="body1">
                  Salaris
                </Typography>
                <Typography sx={c.head__attr__value} variant="body1">
                  {data?.data.meta.salary}
                </Typography>
              </Box>
            </Box>
            <Box sx={c.head__attr}>
              <Box sx={c.head__attr__icon}>
                <IcWorkHourLabel />
              </Box>
              <Box sx={c.head__attr__wrapper}>
                <Typography sx={c.head__attr__title} variant="body1">
                  Dienstverband
                </Typography>
                <Typography sx={c.head__attr__value} variant="body1">
                  {data?.data.meta.work_hour}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="VacatureBody" sx={c.body}>
          <SetInnerHtml>{data?.data.content}</SetInnerHtml>
          <Button
            onClick={() => setIsPopup(true)}
            variant="contained"
            sx={c.body__button}
          >
            Solliciteren
          </Button>
        </Box>
      </Container>
      <FormPopup
        isOpen={isPopup}
        handleClose={() => setIsPopup(false)}
        job={data?.data}
      />
    </Box>
  );
};

export default Single;
