import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "src/components/atoms/Loading";
import Slides from "src/components/backend/Slides";
import SidebarSlide from "src/components/moleculs/Sidebar/SidebarSlide";
import { slideSidebar } from "src/services/slides";
import SwalCatch from "src/utils/helpers/SwalCatch";

export default function Slide() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/slides-sidebar")
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          const errors = res.data?.errors;
          SwalCatch(errors, true);
        }
        const newData = res.data;
        setData(newData);
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        SwalCatch(errors, true);
      });
  }, []);

  const hierarkiData = slideSidebar(data);

  return data.length === 0 ? (
    <Loading />
  ) : (
    <>
      <SidebarSlide isSm data={hierarkiData} />
      <Slides firstData={hierarkiData[0] ?? []} />
    </>
  );
}
