import { Button, Box } from "@mui/material";
import TitleMain from "src/components/atoms/TitleMain";
import PriceListCard from "src/components/moleculs/PriceListCard";
import WrapperContent from "src/components/moleculs/WrapperContent/WrapperContent";
import c from "./LessonPackagesStyle";
import g from "src/utils/MuiStyle/Global";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import useMailto from "src/hooks/useMailto";
import { useEffect, useState } from "react";
import SwalCatch from "src/utils/helpers/SwalCatch";
import axios from "axios";

const dummyData = [
  {
    numberList: "1",
    titleList: "Cursist",
    subTitleList: "Rijinstructeur worden via belevingstraject",
    descriptionList:
      "Rijinstructeur worden met één been op de werkvloer? Dan is de opleiding rijschoollesgever via belevingstraject echt iets voor jou. Wat de troeven zijn van het belevingstraject kan je ontdekken in de video",
    ytList: "https://www.youtube.com/watch?v=hj9v16i4iMc",
    data: [
      {
        heading: "Syntra",
        title: "Opleiding rijschoollesgever",
        content:
          "Je ontvangt een registratie- code tijdens de eerste lesweek. Hiermee krijg je gratis toegang tot alle lesmaterialen.",
        footer: "GRATIS",
        isButton: false,
        buttonName: "Wachtwoord aanvragen",
        buttonLink: "/register/#via-syntra",
      },
      {
        heading: "Via rijscholen",
        title: "Belevingstraject workshops + praatcafé's",
        content: "Toegang tot alle lesmateriaal (inschrijving via platform)",
        footer: "€650/jaar",
        isButton: false,
        buttonName: "Aankopen",
        buttonLink: "https://buy.stripe.com/aEUdTvgzE2RD2n6aEJ",
      },
      {
        title: "Bekijk hier alle deelnemende rijscholen",
        isButton: false,
        buttonLink: "/#contact",
        buttonName: "Bekijk rijscholen",
      },
    ],
  },
  {
    numberList: "2",
    titleList: "Zelfstudie",
    subTitleList: "Rijinstructeur worden via zelfstudie",
    descriptionList:
      "Studeer op jouw eigen tempo voor het schriftelijk en mondeling examen, dat georganiseerd wordt door de overheid. Vooreerst wordt het cursusmateriaal gratis aangeboden, hoe dit kan en hoe je nog extra ondersteuning kan krijgen in het behalen van je brevet verneem je in de volgende video",
    ytList: "https://www.youtube.com/watch?v=5IlpkNLX-Y0",
    data: [
      {
        role: 0,
        heading: "Basis pakket",
        title: "Examenleerstof",
        content: "Verkeersreglementering \nverkeerswetgeving \nAutomechanica",
        footer: "GRATIS",
        isButton: false,
        buttonLink: "/register/#zelfstudie",
        buttonName: "Wachtwoord aanvragen",
      },
      {
        role: 1,
        heading: "Plus pakket",
        title: "Basic pakket \n+ voorbeeld examenvragen \n+ MC oefenvragen",
        content: "Verkeersreglementering \nVerkeerswetgeving \nAutomechanica",
        footer: "€75/jaar",
        isButton: false,
        buttonLink: " https://buy.stripe.com/8wM9Df1EKcsdf9SbIO",
        buttonName: "Aankopen",
      },
      {
        role: 2,
        heading: "Compleet pakket",
        title: "Plus pakket + 2 handboeken",
        content: "Wegverkeerswet \nWegcode",
        footer: "€150/jaar",
        isButton: false,
        buttonLink: "https://buy.stripe.com/5kA4iV1EKgIte5OcMQ",
        buttonName: "Aankopen",
      },
    ],
  },
];

const LessonPackages = () => {
  const [dataWorkshop, setDataWorkshop] = useState({ title: "", file: "" });
  const onClickMailto = () => {
    useMailto(
      "karin@platform-rijschoollesgever.be",
      "Ondersteuning modellessen",
      ""
    );
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      axios
        .get("/api/data-workshop")
        .then((res) => {
          setDataWorkshop(res.data.document);
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors;
          console.log("ik komh ir !!");
          SwalCatch(errors);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <WrapperContent>
      <TitleMain text="Lespakketten" to="/" sxs={c.title} />
      <Box sx={c.wrapperList}>
        {dummyData?.map((item) => {
          return (
            <PriceListCard
              key={item.numberList}
              numberList={item.numberList}
              titleList={item.titleList}
              data={item.data}
              subTitleList={item.subTitleList}
              descriptionList={item.descriptionList}
              ytUrl={item.ytList}
            />
          );
        })}
      </Box>
      <Box sx={c.wrapperButton}>
        <Button variant="contained" onClick={onClickMailto}>
          Ondersteuning modellessen
        </Button>
        <Button
          href={dataWorkshop?.file}
          target="_blank"
          variant="contained"
          startIcon={<CloudDownloadOutlinedIcon />}
          sx={{ ...g.button.secondary, ...g.button.hasIcon }}
        >
          {dataWorkshop?.title ?? "Data workshops"}
        </Button>
      </Box>
    </WrapperContent>
  );
};

export default LessonPackages;
