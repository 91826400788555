import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const ButtonListStyle = AsSxObject({
  wrapper: {
    display: "flex",
    padding: "36px 20px",
    borderBottom: "4px solid",
    borderBottomColor: g.color.primary,
    justifyContent: "space-between",
    position: "relative",
  },
  icon: {
    height: "32px",
    width: "32px",
  },
  text: {
    display: "flex",
    alignItems: "center",
  },
});
export default ButtonListStyle;
