import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const SidebarStyle = AsSxObject({
  sidebarWrapperSm: {
    width: "360px",
  },
  sidebarWrapper: {
    position: "fixed",
    height: "100vh",
    width: "250px",
    display: "flex",
    backgroundColor: "#fcfcfc",
    boxShadow: "0px 3px 6px #00000029",
    overflowY: "auto",
    zIndex: "99",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fcfcfc",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "@media(max-width: 1021px)": {
      transform: "translateX(-100%)",
      transition: "all .3s ease-in-out",
      "&.open": {
        transform: "translateX(0)",
        transition: "all .3s ease-in-out",
      },
    },
  },
  subSidebarWrapper: {
    display: "none",
  },
  subSidebarCloseBtn: {
    display: "block",
    position: "absolute",
    top: "16px",
    right: "16px",
    "@media(min-width: 1021px)": {
      display: "none",
    },
  },
  subSidebarWrapperSm: {
    padding: "82px 15px 20px 15px",
    width: "100%",
    marginLeft: "76px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fcfcfc",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "@media(max-width: 1021px)": {
      transform: "translateX(-100%)",
      transition: "all .3s ease-in-out",
      "&.open": {
        transform: "translateX(0)",
        transition: "all .3s ease-in-out",
      },
    },
  },
  subSidebarListItem: {
    borderBottom: "solid 1px #00000080",
    position: "relative",
  },
  subSidebarListItemNoBorder: {
    paddingBlock: "0px",
    position: "relative",
  },
  subSidebarListItemLinkActive: {
    ".MuiTypography-root": {
      color: g.color.primary,
    },
  },
  subSidebarListBox: {},
  subSidebarListBoxNoBorder: {
    marginTop: "20px",
    marginBottom: "-20px",
  },
  subSidebarListItemChild: {
    marginTop: "16px",
    paddingLeft: "10px",
  },
  subSidebarListItemChildLink: {
    width: "100%",
    height: "100%",
  },
  subSidebarListItemChildLinkActive: {
    ".MuiTypography-root": {
      color: g.color.primary,
    },
  },
  subSidebarText: {
    fontFamily: "Metropolis-Medium",
    fontWeight: "500",
    fontSize: "16px",
  },
  subSidebarLink: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  subSidebarActive: {
    color: g.color.primary,
    ".MuiTypography-root": {
      fontFamily: "Metropolis-Bold",
      fontWeight: "700",
    },
  },
  asideWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    height: "100vh",
  },
  asideWrapperSm: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    maxWidth: "80px",
    borderRight: "solid 1px #00000019",
    height: "100vh",
    position: "fixed",
    top: "0",
    left: "0",
    overflowY: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  logo: {
    width: "100%",
    objectFit: "contain",
    flex: "0 0 50px",
    marginTop: "12px",
  },
  mainMenu: {
    flex: "auto",
    display: "flex",
    flexDirection: "column",
    marginTop: "52px",
  },
  mainMenuSm: {
    marginTop: "16px",
  },
  footMenu: {
    marginTop: "52px",
  },
  divider: {
    marginBlock: "16px",
  },
  mobileNav: {
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(min-width: 1021px)": {
      display: "none",
    },
  },
  mobileLogo: {
    width: "50px",
    objectFit: "contain",
  },
  mobileHamburger: {
    display: "block",
    width: "30px",
    height: "30px",
    objectFit: "contain",
    marginLeft: "auto",
  },
});

export default SidebarStyle;
