import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const MenuStyle = AsSxObject({
  wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    columnGap: "24px",
    padding: "8px 0",
  },
  wrapperSm: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    columnGap: "24px",
    padding: "8px 0",
    position: "relative",
  },
  title: {
    fontFamily: "Metropolis-Medium",
    fontWeight: "500",
  },
  slim: {
    fontFamily: "Metropolis-Medium",
    fontWeight: "500",
  },
  avatar: {
    height: "40px",
    width: "40px",
    borderRadius: "40px",
    objectFit: "cover",
    flex: "0 0 40px",
  },
  icon: {
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 40px",
  },
  dividerActive: {
    position: "absolute",
    borderWidth: "2px",
    borderColor: g.color.primary,
    backgroundColor: g.color.primary,
    right: "-22px",
    top: "50%",
    transform: "translateY(-62%)",
  },
});

export default MenuStyle;
