import { AsSxObject } from "src/utils/AsSxObject";

const FormPopupStyle = AsSxObject({
  popup: {
    ".MuiDialog-paper": {
      width: "100%",
      borderRadius: "16px",
    },
  },
  forms: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "20px",
    gap: "16px",
    "@media(max-width: 600px)": {
      paddingInline: "0px",
    },
  },
  field_error: {
    color: "red",
  },
  field: {
    width: "100%",
  },
  field_input_hidden: {
    display: "none",
  },
  button: {
    width: "60%",
    marginTop: "30px",
    "@media(max-width: 600px)": {
      marginTop: "12px",
      width: "fit-content",
    },
  },
  wrapper_cv: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    columnGap: "16px",
  },
  wrapper_cv__helper: {
    opacity: "0.5",
    flex: "0 0 100%",
  },
  button_cv: {
    width: "fit-content",
    ":hover": {
      color: "white",
    },
  },
  caption: {
    fontSize: "12px",
    fontFamily: "Metropolis-medium",
  },
});

export default FormPopupStyle;
