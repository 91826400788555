import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import AccordionList from "src/components/atoms/AccordionList";
import ButtonList from "src/components/atoms/ButtonList";
import TitleList from "src/components/atoms/TitleList";
import ExternalLink from "src/components/atoms/ExternalLink";
import c from "./ContactStyle";
import GaleryLogo from "./GaleryLogo";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import useHubspotFormV2 from "@/src/hooks/useHubspotFormV2";

const Contact = () => {
  const [accordion, setAccordion] = useState("0");

  useHubspotFormV2(true);
  const accordionHandleClick = (id) => () => {
    setAccordion((prev) => (id === prev ? "0" : id));
  };

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <Container sx={c.wrapperTop}>
        <Typography variant="h2">Contact</Typography>
      </Container>
      <Box sx={c.wrapperBottom}>
        <Container sx={c.container}>
          <Box sx={c.information}>
            <Box
              sx={c.img}
              component="img"
              alt="Car Image"
              src="/images/dummy/IMG_5395.png"
            />
            <Box sx={c.content}>
              <Typography variant="h4">Over Karin Verleysen</Typography>
              <Typography variant="body1">
                Karin Verleysen runt haar eigen éénmansrijschool 'Rij-Huis' te
                Alveringem. Daarnaast werkt ze bij Syntra West als hoofddocent
                'Verkeer en methodiek' voor de opleiding Rijschoollesgever en
                als docent 'Nascholing wegcode C & D'. Bovendien is Karin ook
                gekend via de Vlaamse Stichting Verkeerskunde, als docent
                'Bijscholing rijschoollesgever'.
              </Typography>
              <Typography variant="body1" sx={c.quote}>
                “De noodkreet naar lesgevers die nu heerst in de rijschoolsector
                is zo groot dat er meer nodig is om de kandidaat lesgevers over
                de streep te trekken. Dit platform wil daarbij de aftrap geven.
                Het verschaft degelijke informatie over de mogelijkheden hoe je
                je brevet kan halen aan een betaalbare prijs.” <br />- Karin
                Verleysen
              </Typography>
            </Box>
          </Box>
          <Box
            sx={c.form}
            className="js-hsform"
            id="form-contact"
            data-portal-id="27183479"
            data-form-id="443e70fe-5ed2-4dfd-9146-ccabe3375cb1"
            data-region="eu1"
          ></Box>
          <Box sx={c.listWrapper}>
            <TitleList
              number="1"
              text="Belevingstraject"
              sxs={c.listTitle}
              id="Belevingstraject"
            />
            <Box>
              <GaleryLogo />
            </Box>
            <Box sx={c.listButton}>
              <ExternalLink href="mailto:karin@platform-rijschoollesgever.be?subject=Inschrijven als rijschool">
                <Button variant="contained">Inschrijven als rijschool</Button>
              </ExternalLink>
            </Box>
          </Box>
          <Box sx={c.listWrapper}>
            <TitleList
              number="2"
              text="Erkend opleidingsinstituut"
              sxs={c.listTitle}
            />
            <Box sx={c.listItems}>
              <Box sx={c.listItemsCol}>
                <AccordionList
                  text="Syntra West"
                  id="1"
                  state={accordion}
                  onClick={accordionHandleClick("1")}
                  desc={`<a target="_blank" href="https://www.google.com/maps/place/Syntra+West+Ten+Briele/@51.187294,3.2253117,17z/data=!3m1!4b1!4m5!3m4!1s0x47c350f20dfc2a8b:0x19131994accefcac!8m2!3d51.187294!4d3.2275004">Campus Ten Briele \nTen Briele 7, 8200 Sint-Michiels </a>\n<a href="mailto:heidi.vuylsteke@syntrawest.be">heidi.vuylsteke@syntrawest.be</a> \n<a href="tel:+32472809544">+32472809544</a>`}
                />
                <AccordionList
                  text="Syntra Campus Mechelen"
                  id="2"
                  state={accordion}
                  onClick={accordionHandleClick("2")}
                  desc={`<a target="_blank" href="https://www.google.com/maps/place/Syntra+AB+Mechelen+Campus/@51.0511621,4.4575794,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3e59dfb566e01:0x31da3bea9c0907c3!8m2!3d51.0511621!4d4.4597681">Syntra Mechelen \nOude Baan 2, 2800 Mechelen </a> \n<a href="mailto:mechelen@syntra-ab.be">mechelen@syntra-ab.be</a>\n<a href="tel:078159999">078 15 99 99</a>`}
                />
                <AccordionList
                  text="Syntra Campus Gent"
                  id="3"
                  state={accordion}
                  onClick={accordionHandleClick("3")}
                  desc={`<a target="_blank" href="https://www.google.com/maps/place/Syntra/@51.025839,3.6603698,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3723d4480bf81:0x2e860f0fbf157977!8m2!3d51.0262938!4d3.66258">Syntra Gent \nAutoweg-Zuid 39051 Gent (Afsnee) </a>\n<a href="mailto:tine.van.langenhove@syntra-mvl.be">tine.van.langenhove@syntra-mvl.be</a> \n<a href="tel:03 760 08 36">03 760 08 36</a>`}
                />
              </Box>
              <Box sx={c.listItemsCol}>
                <AccordionList
                  text="Syntra A-B"
                  id="4"
                  state={accordion}
                  onClick={accordionHandleClick("4")}
                  desc={`<a target="_blank" href="https://www.google.com/maps/place/Syntra+AB+campus+Leuven/@50.8495011,4.7241676,15z/data=!4m2!3m1!1s0x0:0x2bb75487ed01a6c3?sa=X&ved=2ahUKEwj5jYKvu5D4AhVG7XMBHe7UA4kQ_BJ6BAhSEAU">Syntra Campus Leuven \nGeldenaaksebaan 327, 3001 Heverlee</a> \n<a href="mailto:helena.verboomen@syntra-ab.be">helena.verboomen@syntra-ab.be</a> \n<a href="mailto:heidi.mylle@syntra-ab.be">heidi.mylle@syntra-ab.be</a>`}
                />
                <AccordionList
                  text="Syntra PXL Campus Hasselt"
                  id="5"
                  state={accordion}
                  onClick={accordionHandleClick("5")}
                  desc={`<a target="_blank" href="https://www.google.com/maps/place/SyntraPXL+Campus+Hasselt/@50.9372015,5.3611542,17z/data=!4m5!3m4!1s0x47c121733262c097:0x6d1c86199c997564!8m2!3d50.9372015!4d5.3633429">Syntra Campus Hasselt \nTrichterheideweg 7, 3500 Hasselt</a> \n<a href="mailto:rudi.ponthier@syntrapxl.be">rudi.ponthier@syntrapxl.be</a> \n<a href="tel:011 30 32 08">011 30 32 08</a>`}
                />
                <ButtonList text=" " sxs={c.listEmpty} />
              </Box>
            </Box>
          </Box>
          <Box sx={c.listWrapper}>
            <TitleList number="3" text="Zelfstudie" sxs={c.listTitle} />
            <Typography sx={c.listCaption}>
              Online via{" "}
              <Link to="/register" className="link">
                'Registreren'
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Contact;
