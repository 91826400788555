import { AsSxObject } from "src/utils/AsSxObject";

const CategoryItemStyles = AsSxObject({
  button: {
    padding: "8px 16px",
    whiteSpace: "nowrap",
    boxSizing: "content-box",
  },
});

export default CategoryItemStyles;
