import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import c from "./MenuStyle";

/**
 * @typedef {object} Menu
 * @property {string} to
 * @property {string} text
 * @property {string} [imageSrc]
 * @property {any} [icon]
 * @property {boolean} [isSlim]
 * @property {boolean} isSm
 * @property {boolean} [isActive]
 * @property {() => void} [onClick]
 *
 * @param {Menu} props
 */

const Menu = (props) => {
  const {
    to,
    imageSrc,
    icon,
    text,
    isActive = false,
    isSlim = false,
    onClick,
    isSm,
  } = props;

  return isSm ? (
    <Link to={to} onClick={onClick}>
      <Box sx={c.wrapperSm}>
        {imageSrc ? (
          <Box component={"img"} alt="Avatar" src={imageSrc} sx={c.avatar} />
        ) : (
          <Box sx={c.icon}>{icon}</Box>
        )}
        {isActive && (
          <Divider
            variant="middle"
            orientation="vertical"
            sx={c.dividerActive}
          />
        )}
      </Box>
    </Link>
  ) : (
    <Link to={to} onClick={onClick}>
      <Box sx={c.wrapper}>
        {imageSrc ? (
          <Box component={"img"} alt="Avatar" src={imageSrc} sx={c.avatar} />
        ) : (
          <Box sx={c.icon}>{icon}</Box>
        )}
        <Typography
          sx={{ ...c.title, ...(isSlim ? c.slim : {}) }}
          variant="body1"
        >
          {text}
        </Typography>
      </Box>
    </Link>
  );
};

export default Menu;
