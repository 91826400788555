import { AsSxObject } from "src/utils/AsSxObject";

const LoginFormStyle = AsSxObject({
  forms: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    width: "100%",
    gap: "16px",
    "@media(max-width: 600px)": {
      paddingInline: "24px",
    },
  },
  formLogo: {
    height: "100px",
    objectFit: "contain",
    marginBottom: "60px",
    "@media(max-width: 600px)": {
      height: "50px",
      marginBottom: "16px",
      objectPosition: "left",
    },
  },
  field: {
    width: "85%",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  button: {
    width: "60%",
    marginTop: "30px",
    "@media(max-width: 600px)": {
      marginTop: "12px",
    },
  },
  caption: {
    fontSize: "12px",
    fontFamily: "Metropolis-medium",
  },
});

export default LoginFormStyle;
