import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const StudiemateriaalStyle = AsSxObject({
  container: { ...g.container, ...{ padding: "78px 16px" } },
  wrapperTop: {
    marginTop: "30px",
    "@media(min-width: 600px)": {
      marginTop: "60px",
    },
    "@media(min-width: 1020px)": {
      paddingLeft: "65px",
      paddingRight: "65px",
      marginTop: "112px",
    },
  },
  wrapperBottom: {
    background: g.color.neutral[200],
    paddingBottom: "30px",
    // marginBottom: "-145px",
    marginTop: "60px",
  },
  wrapperCards: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "wrap",
    columnGap: "82px",
    rowGap: "56px",
    "@media(max-width: 1020px)": {
      columnGap: "30px",
      rowGap: "30px",
    },
  },
  card: {
    flex: "0 0 calc(33.333% - (82px * 2 / 3))",
    "@media(max-width: 1020px)": {
      flex: "0 0 calc(50% - (30px * 1 / 2))",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  text: {
    marginTop: "80px",
    "@media(max-width: 1020px)": {
      marginTop: "30px",
    },
  },
  content: {
    marginTop: "56px",
    whiteSpace: "pre-wrap",
  },
});

export default StudiemateriaalStyle;
