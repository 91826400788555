const { AsSxObject } = require("src/utils/AsSxObject");

const QuestionOpenStyle = AsSxObject({
  answerWrapper: {
    height: 0,
    overflow: "hidden",
    opacity: 0,
    transition: "all ease-in-out .3s",
  },
  answerWrapperOpen: {
    height: "fit-content",
    opacity: 1,
    transition: "all ease-in-out .3s",
    paddingBlock: "30px 56px",
  },
  footerBack: {
    display: "flex",
    justifyContent: "flex-start",
    position: "absolute",
    bottom: "0",
    left: "0",
  },
  footerSlide: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: "0",
    right: "0",
  },
  footerSlidePlusMinus: {
    display: "flex",
    gap: "16px",
  },
  circleIconTrue: {
    fontFamily: "Metropolis-Regular",
    padding: "24px",
    width: "10px",
    height: "10px",
    minWidth: "0",
    backgroundColor: "#2946D6",
    color: "#E4E6F5",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#2946D6",
      color: "#E4E6F5",
    },
  },
  circleIconFalse: {
    backgroundColor: "#E4E6F5",
    fontFamily: "Metropolis-Regular",
    color: "#2946D6",
    padding: "24px",
    width: "10px",
    height: "10px",
    minWidth: "0",
    cursor: "not-allowed",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#E4E6F5",
      color: "#2946D6",
    },
  },
});

export default QuestionOpenStyle;
