import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import TitleMain from "src/components/atoms/TitleMain";
import WrapperContent from "src/components/moleculs/WrapperContent/WrapperContent";
import useRole from "src/hooks/useRole";
import c from "./AccountStyle";
import Info from "./Info";
import ListPaket from "./ListPaket";

const data = [
  {
    title1: "Basis pakket",
    title2: "Examenleerstof",
    desc1: "Verkeersreglementering \nVerkeerswetgeving",
    desc2: "Automechanica",
    price: "GRATIS",
  },
  {
    title1: "Plus pakket",
    title2: "Basic pakket + voorbeeld examenvragen + MC oefenvragen",
    desc1: "Verkeersreglementering \nVerkeerswetgeving",
    desc2: "Automechanica",
    price: "€75/jaar",
  },
  {
    title1: "Compleet lespakket",
    title2: "Plus pakket + 2 handboeken",
    desc1: "Wegverkeerswet",
    desc2: "Wegcode",
    price: "€150 / jaar",
  },
  {
    title1: "Compleet lespakket",
    title2: "Plus pakket + 2 handboeken",
    desc1: "Wegverkeerswet",
    desc2: "Wegcode",
    price: "€150 / jaar",
  },
];

const Account = () => {
  const userRole = useRole();
  const packageList = [data[userRole]];

  return (
    <WrapperContent isLight>
      <Box sx={c.wrapper}>
        <Info />
        <Box sx={c.wrapperPaket}>
          <TitleMain text="Jouw lespakket" />
          <Box sx={c.paketList}>
            {packageList?.map((item, i) => {
              return (
                <ListPaket
                  key={`listPaket-${i}`}
                  title1={item.title1}
                  title2={item.title2}
                  desc1={item.desc1}
                  desc2={item.desc2}
                  price={item.price}
                />
              );
            })}
          </Box>
          <Link to={"/lesson-packages"}>
            <Button variant="contained">Pakket wijzigen</Button>
          </Link>
        </Box>
      </Box>
    </WrapperContent>
  );
};

export default Account;
