const { AsSxObject } = require("src/utils/AsSxObject");

const StaticContentStyle = AsSxObject({
  wrapper: {
    width: "100%",
    position: "relative",
    marginBottom: "64px",
  },
});

export default StaticContentStyle;
