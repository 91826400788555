import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const SocialMediaStyle = AsSxObject({
  wrapper: {
    boxSizing: "border-box",
    maxHeight: "30px",
    display: "flex",
    columnGap: "16px",
  },
  isNone: {
    opacity: 0,
    visibility: "hidden",
    display: "none",
  },
  icon: {
    color: g.color.primary,
    "&:hover": {
      opacity: "0.5",
    },
  },
  iconWhite: {
    color: "#ffffff",
  },
});

export default SocialMediaStyle;
