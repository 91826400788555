import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Account
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Account} param1
 */

export default function ArrowRight({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 12H20.25"
          stroke="#2946D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 5.25L20.25 12L13.5 18.75"
          stroke="#2946D6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
