import c from "./FaqChildStyle";
import g from "src/utils/MuiStyle/Global";
import WrapperContent from "src/components/moleculs/WrapperContent";
import { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import Swipper from "src/components/moleculs/Swipper";
import { AddSharp, RemoveSharp } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import QuestionOpen from "../QuestionOpen";
import Question from "../Question";
import axios from "axios";
import SwalCatch from "src/utils/helpers/SwalCatch";
import Loading from "src/components/atoms/Loading";

const FaqChild = ({ firstData }) => {
  const param = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [slideIndex, setSlideIndexModal] = useState(0);
  const [faqDatas, setFaqDatas] = useState([]);

  const title = param.id
    ? param.id.split("-").join(" ")
    : firstData.slug.split("-").join(" ");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (isMounted) {
      setSlideIndexModal(0);

      axios
        .get(`/api/questions/${param.id ?? firstData?.slug}`, {
          signal: controller.signal,
        })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            const errors = res.data?.errors;
            SwalCatch(errors, true);
          }
          const newData = res.data.items;
          setFaqDatas(newData);
          setIsLoad(false);
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors;
          if (err?.message !== "canceled") {
            SwalCatch(errors, true);
          }
        });
    }

    return () => {
      isMounted = false;
      setSlideIndexModal(0);
      setIsLoad(true);
      controller.abort();
    };
  }, [param]);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <WrapperContent isLight isSm>
      {isLoad ? (
        <Loading sxs={{ position: "absolute", background: "transparent" }} />
      ) : (
        <>
          <Swipper
            title={title}
            handlePopupProps={handleOpen}
            isScrollbar
            onSlideChange={(prev) => {
              setSlideIndexModal(prev);
            }}
            initialSlide={slideIndex}
            allowTouchMove={false}
            datas={faqDatas}
            content={(item, index) => {
              return item.questions.type === "open_vraag" ? (
                <QuestionOpen item={item} />
              ) : (
                <Question item={item} />
              );
            }}
            footer={() => <div></div>}
          />
          {/* <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={c.modal}
          >
            <Box sx={c.modalWrapper}>
              <Swipper
                title={faqChildTitle}
                handlePopupProps={handleClose}
                iconToggle
                initialSlide={slideIndex}
                allowTouchMove={false}
                onSlideChange={(prev) => setSlideIndexModal(prev)}
                datas={faqChildData}
                content={(item, index) => {
                  return isOpenQuestion ? (
                    <QuestionOpen item={item} />
                  ) : (
                    <Question item={item} />
                  );
                }}
                footer={() => <div></div>}
              />
            </Box>
          </Modal> */}
        </>
      )}
    </WrapperContent>
  );
};

export default FaqChild;
