import { Box } from "@mui/material";
import ExternalLink from "src/components/atoms/ExternalLink";
import c from "./GaleryLogoStyle";
import { useEffect, useState } from "react";
import SwalCatch from "src/utils/helpers/SwalCatch";
import axios from "axios";

const GaleryLogo = () => {
  const datas__ = [
    {
      alt: "Regnier Bultinck",
      img: "kortrijkserijschool.png",
      url: "http://www.kortrijkserijschool.be",
    },
    {
      alt: "Lode Vandenberghe",
      img: "scholenaandeleie.png",
      url: "http://www.scholenaandeleie.be/rijschool/",
    },
    {
      alt: "Anne-Marie Topper",
      img: "topper.png",
      url: "http://www.topper.be",
    },
    // {
    //   alt: "Nico Bracke",
    //   img: "ardooiseautorijschool.png",
    //   url: "http://www.ardooiseautorijschool.be",
    // },
    {
      alt: "Kurt David",
      img: "rijschoolvti-ieper.png",
      url: "http://www.rijschoolvti-ieper.be",
    },
    {
      alt: "Marc Vanherrewegen",
      img: "rijschool-vtir.png",
      url: "http://www.rijschool-vtir.be",
    },
    {
      alt: "Liesbeth Willemyns",
      img: "voc-brugge.png",
      url: "http://www.voc-brugge.be",
    },
    {
      alt: "Jean-Marie Dehaene",
      img: "deheuvellandserijschool.png",
      url: "http://www.deheuvellandserijschool.be",
    },
    {
      alt: "Hubert Verelst",
      img: "rijscholenlust.png",
      url: "http://www.rijscholenlust.be",
    },
    {
      alt: "Karin Verleysen",
      img: "rij-huis.png",
      url: "http://www.rij-huis.be",
    },
    {
      alt: "Alain Weemeeuw",
      img: "vtip.png",
      url: "http://www.vtip.be/rijschool/",
    },
    {
      alt: "Leen Vanslambrouck",
      img: "rijschoolerasmus.png",
      url: "http://www.rijschoolerasmus.be",
    },
    {
      alt: "Erik Devoldere",
      img: "waregemserijschool.png",
      url: "http://www.waregemserijschool.be",
    },
    {
      alt: "Guy Vansteenkiste",
      img: "autorijschoolkta.png",
      url: "http://www.autorijschoolkta.be",
    },
    {
      alt: "Dirk Covemaeker",
      img: "secura.png",
      url: "http://www.secura.be",
    },
    {
      alt: "Ardooise Autorijschool",
      img: "ardooiserijschool.png",
      url: "https://www.ardooiseautorijschool.be",
    },
    {
      alt: "DriveWell",
      img: "drivewell.jpeg",
      url: "https://www.drivewell.be",
    },
    {
      alt: "Pinkers",
      img: "pinkers.png",
      url: "https://www.pinkers.be",
    },
  ];
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      axios
        .get("/api/partners")
        .then((res) => {
          setDatas(res.data.items);
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors;
          console.log("ik komh ir !!");
          SwalCatch(errors);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Box className="GaleryLogo" sx={c.wrapper}>
      {datas.map((item, key) => {
        return (
          item.image && (
            <ExternalLink
              key={item.title.split(" ").join("-") + key}
              href={item.url}
              target={"_blank"}
              sx={c.items}
            >
              <Box
                sx={c.logoImg}
                component="img"
                alt={item.title}
                src={item.image}
              />
            </ExternalLink>
          )
        );
      })}
    </Box>
  );
};

export default GaleryLogo;
