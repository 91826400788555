const { AsSxObject } = require("src/utils/AsSxObject");

const SlidesStyle = AsSxObject({
  modal: {
    backgroundColor: "rgba(0,0,0, 0.4)",
    overflow: "auto",
  },
  modalWrapper: {
    padding: "20px",
    maxWidth: "1300px",
    marginInline: "auto",
    "&:focus-visible": {
      outline: "none",
    },
  },
  footerSlide: {
    display: "flex",
    justifyContent: "space-between",
  },
  footerSlideFixed: {
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    bottom: "0",
    width: "100%",
  },
  footerSlidePlusMinus: {
    display: "flex",
    gap: "16px",
  },
  pembatasAntaraQueastionDan_________________________________________________________________________________:
    {},
  questionWrapper: {
    maxWidth: "600px",
    marginInline: "auto",
  },
  questionImage: {
    width: "100%",
    objectFit: "contain",
    height: "250px",
    marginBottom: "16px",
    "@media(min-width: 1025px)": {
      height: "360px",
    },
  },
  questionLabelTrue: {
    background: "aqua",
  },
  questionLabelFalse: {
    background: "red",
  },
  pembatasAntaraSlidePopupDan_________________________________________________________________________________:
    {},
  slidePopupImage: {
    width: "100%",
    objectFit: "contain",
    marginBottom: "16px",
  },
  slideImage: {
    width: "100%",
    objectFit: "contain",
    height: "auto",
    marginBottom: "16px",
  },
});

export default SlidesStyle;
