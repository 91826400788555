import { Box, Typography } from "@mui/material";
import TitleList from "src/components/atoms/TitleList";
import useStore from "src/hooks/useStore";
import YtEmbed from "../YtEmbed";
import Column from "./Column";
import c from "./PriceListCardStyle";

/**
 * @typedef {object} PriceListCard
 * @property {string} id
 * @property {string} numberList
 * @property {string} titleList
 * @property {string} [subTitleList]
 * @property {string} [descriptionList]
 * @property {string} [ytUrl]
 * @property {array} data
 *
 * @param {PriceListCard} props
 */

const PriceListCard = (props) => {
  const store = useStore();
  const dataUser = store.profileAccount.data;
  // @ts-ignore
  const userRole = dataUser?.role ?? 3;

  const {
    numberList,
    titleList,
    data,
    subTitleList,
    descriptionList,
    ytUrl,
    id,
  } = props;
  return (
    <Box>
      <Box id={id} sx={{ position: "relative", top: "-160px" }} />
      <TitleList number={numberList} text={titleList} sxs={c.listTitle} />
      <Typography variant="h4" sx={c.listSubTitle}>
        {subTitleList}
      </Typography>
      <Typography variant="body1">{descriptionList}</Typography>
      {ytUrl && <YtEmbed url={ytUrl} />}
      <Box sx={c.card}>
        <Box sx={c.cardHeading}>
          {data?.map((item, index) => {
            return (
              <Typography
                key={`${item.heading}-${index}`}
                variant="h4"
                sx={c.cardColumn}
              >
                {item.heading}
              </Typography>
            );
          })}
        </Box>
        <Box sx={c.cardBody}>
          {data?.map((item, index) => {
            const hasBorderInline =
              index + 1 === 2 ? c.cardColumn.hasBorderInline : {};
            return (
              <Box
                key={`${item.heading}-${index}`}
                sx={{ ...c.cardColumn, ...hasBorderInline }}
              >
                <Column
                  heading={item.heading}
                  title={item.title}
                  content={item.content}
                  footer={item.footer}
                  isButton={item.isButton}
                  buttonLink={item.buttonLink}
                  buttonName={item.buttonName}
                  isDisabledButton={item.role <= userRole}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PriceListCard;
