const { AsSxObject } = require("src/utils/AsSxObject");

const RenderedYouTubeStyle = AsSxObject({
  embedWrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
    marginBottom: "20px" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
  },
  embedResponsive: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
});

export default RenderedYouTubeStyle;
