import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import c from "./AuthFooterStyle";

const AuthFooter = () => {
  return (
    <Box sx={c.wrapper}>
      <Typography sx={c.item}>
        <Link to="/">© Platform Rijschoollesgever</Link>
      </Typography>
      <Typography sx={c.item}>
        <Link to="/privacy-policy">Privacy policy</Link>
      </Typography>
      <Typography sx={c.item}>
        This platform was Upproved by{" "}
        <a href="https://www.shakeUp.be" target="_blank">
          ShakeUp
        </a>
      </Typography>
    </Box>
  );
};

export default AuthFooter;
