const questionSidebar = (arr) => {
  let datas = [];

  const recursive = (value) => {
    let childs = [];
    if (typeof value.childs === "object") {
      Object.entries(value.childs).forEach(([keyChild, valueChild], j) => {
        childs[j] = {
          id: keyChild,
          title: valueChild.title,
          slug: valueChild.slug,
          childs: recursive(valueChild),
        };
      });
    }

    return childs;
  };

  if (arr?.items) {
    Object.entries(arr.items).forEach(([key, value], i) => {
      datas[i] = {
        id: key,
        title: value.title,
        slug: value.slug,
        childs: recursive(value),
      };
    });
  }

  return datas;
};

export { questionSidebar };
