import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BelevingstrajectForm from "./Belevingstraject";
import c from "./RegisterStyle";
import RegisterTabPanel from "./RegisterTabPanel";
import ViaSyntra from "./ViaSyntra/ViaSyntra";
import Zelfstudie from "./Zelfstudie";

function a11yProps(index) {
  return {
    id: `register-tab-${index}`,
    "aria-controls": `register-tabpanel-${index}`,
  };
}

const Register = () => {
  const location = useLocation();
  const isZelf = location.pathname === "/register/zelfstudie";
  const [value, setValue] = useState(isZelf ? 2 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const bg = ["bg-register-0.jpg", "bg-register-1.jpg", "bg-register-2.jpg"];

  const data = [
    {
      param: "/register/#Belevingstraject",
      label: "Belevingstraject",
      element: <BelevingstrajectForm />,
    },
    {
      param: "/register/#via-syntra",
      label: "Via Syntra",
      element: <ViaSyntra />,
    },
    {
      param: "/register/#zelfstudie",
      label: "Zelfstudie",
      element: <Zelfstudie />,
      // isDisable: !isZelf,
    },
  ];

  useEffect(() => {
    data.map((item, index) => {
      if (item.param === `/register/${location.hash}`) {
        setValue(index);
        return true;
      }
      return false;
    });
  });

  return (
    <Box sx={c.wrapper}>
      <Link to="/">
        <Box sx={c.logo} component="img" alt="Logo" src="/images/logo-sm.svg" />
      </Link>
      <Box sx={c.formWrapper}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={c.tabs}
        >
          {data.map((item, i) => {
            return (
              <Tab
                key={item.label}
                label={item.label}
                disabled={item.isDisable}
                component={Link}
                to={item.param}
                {...a11yProps(i)}
              />
            );
          })}
        </Tabs>
        {data.map((item, i) => {
          return (
            <RegisterTabPanel key={item.label} value={value} index={i}>
              {item.element}
            </RegisterTabPanel>
          );
        })}
      </Box>
      <Box
        sx={c.img}
        component="img"
        alt="Background Login"
        src={`/images/dummy/${bg[value]}`}
      ></Box>
    </Box>
  );
};

export default Register;
