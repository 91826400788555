import Home from "../pages/Home";
import PrivacyPolice from "src/pages/PrivacyPolice";
import AlgemeneVoorwaarden from "src/pages/AlgemeneVoorwaarden";
import { Navigate } from "react-router-dom";
import Blog from "src/pages/blogs/Blog";
import BlogSingle from "src/pages/blogs/BlogSingle";
import Success from "src/pages/Success";
import VacatureOverview from "src/pages/vacatures/VacatureOverview";
import VacatureSingle from "src/pages/vacatures/VacatureSingle";

const publicRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/algemene-info",
    element: <Home />,
  },
  {
    path: "/studiemateriaal",
    element: <Home />,
  },
  {
    path: "/brevet-rijinstructeur",
    element: <Home />,
  },
  {
    path: "/prijs-rijinstructeur-lespakketten",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Home />,
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
  {
    path: "/blogs/:slug",
    element: <BlogSingle />,
  },
  {
    path: "/vacatures",
    element: <VacatureOverview />,
  },
  {
    path: "/vacatures/:slug",
    element: <VacatureSingle />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolice />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/algemene-voorwaarden",
    element: <AlgemeneVoorwaarden />,
  },
  {
    path: "/opleidingen",
    element: <Navigate to="/#prijs-rijinstructeur-lespakketten" replace />,
  },
];

export default publicRoutes;
