import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import useForm from "src/hooks/useForm";
import { setData } from "src/store/profileAccount";
import { setToken } from "src/store/userAccount";
import SwalCatch from "src/utils/helpers/SwalCatch";
import useMailto from "src/hooks/useMailto";
import useRestructureData from "src/hooks/useRestructureData";
import c from "./BelevingstrajectStyle";

const BelevingstrajectForm = () => {
  const formRef = useRef(null);
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      name: "",
      firstname: "",
      email: "",
      password: "",
      repeatPassword: "",
      aggrement: false,
    });
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    // const bodyEmail = {
    //   ["Naam"]: payload.name,
    //   ["Voornaam"]: payload.firstname,
    //   ["E-mail"]: payload.email,
    // };

    if (fnIsValid(formRef)) {
      const bodyApi = {
        email: payload.email,
        password: payload.password,
      };

      if (fnIsValid(formRef)) {
        // useMailto("karin@platform-rijschoollesgever.be", "Zelfstudie", bodyEmail);
        if (payload.password === payload.repeatPassword) {
          axios
            .post("/api/register", bodyApi)
            .then((res) => {
              if (res.status === 200) {
                const userToken = res.data.access_token;
                dispatch(setToken(userToken));

                axios
                  .get("/api/user", {
                    headers: {
                      Authorization: `Bearer ${userToken}`,
                    },
                  })
                  .then((res) => {
                    const data = res.data.date;
                    dispatch(setData(data));

                    window.open(
                      "https://checkout.stripe.com/c/pay/cs_live_a10SMTA0AueiVjOkzkumKBt2OB0tFWu0BJOFCRvUrxb162FeDZxvNFkfNr#fidkdWxOYHwnPyd1blppbHNgWjA0STxGRDZNXU91MkhxaHF1MWZkUE5kbHBNN212TWowcDFJVkhJYEo9Y2N2RFw9bWpmRnFQQEZ3X11ERGMxVn09SHxHbzVNVmNVUGRRT3BLVzZTNWloY3xSNTVtcjVkczNqSycpJ3VpbGtuQH11anZgYUxhJz8nZEBQYVFzYn9AN1dBN2szZEBPJyknd2BjYHd3YHdKd2xibGsnPydtcXF1dj8qKnJycit1aWRxY2p3aCh3bG92Zm1qamlpYHZiYHNgdytnYConeCUl",
                      "_blank"
                    );
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              const errors =
                err?.response?.data?.errors?.email ??
                err?.response?.data?.errors?.password;
              SwalCatch([{ message: errors[0] }]);
            });
        } else {
          SwalCatch([{ message: "Wachtwoord komt niet overeen" }]);
        }
      }
    }
  };
  return (
    <Box sx={c.wrapper}>
      <Typography variant="body1">
        Het belevingstraject (€650) biedt toegang tot alle lesmateriaal. Er
        zullen verschillende workshops en praatcafé's georganiseerd worden om
        het lesmateriaal volledig onder de knie te krijgen.
      </Typography>

      <Box
        sx={c.fields}
        component="form"
        noValidate
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <TextField
          sx={c.field}
          id="name"
          label="Naam"
          variant="outlined"
          name="name"
          value={payload.name}
          onChange={fnOnChange("name")}
          onInvalid={fnOnInvalid("name", "Het naam moet ingevuld zijn!")}
          required
          // @ts-ignore
          error={valid.name}
          helperText={message.name}
        />
        <TextField
          sx={c.field}
          id="firstname"
          label="Voornaam"
          variant="outlined"
          name="firstname"
          value={payload.firstname}
          onChange={fnOnChange("firstname")}
          onInvalid={fnOnInvalid(
            "firstname",
            "Het voornaam moet ingevuld zijn!"
          )}
          required
          // @ts-ignore
          error={valid.firstname}
          helperText={message.firstname}
        />
        <TextField
          sx={c.field}
          id="email"
          label="E-mail"
          variant="outlined"
          name="email"
          value={payload.email}
          onChange={fnOnChange("email")}
          onInvalid={fnOnInvalid("email", "Het e-mail moet ingevuld zijn!")}
          required
          // @ts-ignore
          error={valid.email}
          helperText={message.email}
        />
        <TextField
          sx={c.field}
          id="password"
          label="Wachtwoord"
          variant="outlined"
          name="password"
          type="password"
          onChange={fnOnChange("password")}
          onInvalid={fnOnInvalid(
            "password",
            " Het wachtwoord moet ingevuld zijn!"
          )}
          required
          value={payload.password}
          // @ts-ignore
          error={valid.password}
          helperText={message.password}
        />

        <TextField
          sx={c.field}
          id="repeatPassword"
          label="Herhaal Wachtwoord"
          variant="outlined"
          name="repeatPassword"
          type="password"
          onChange={fnOnChange("repeatPassword")}
          onInvalid={fnOnInvalid(
            "password",
            " Het wachtwoord moet ingevuld zijn!"
          )}
          required
          value={payload.repeatPassword}
          // @ts-ignore
          error={valid.repeatPassword}
          helperText={message.repeatPassword}
        />
        <FormControlLabel
          sx={c.field}
          control={
            <Checkbox
              defaultChecked={false}
              onChange={fnOnChange("aggrement", true)}
            />
          }
          label="Jullie mogen mijn gegevens bijhouden voor verdere communicatie *"
        />

        <Button sx={c.button} type="submit" variant="contained">
          Pakket aankopen
        </Button>
      </Box>
    </Box>
  );
};

export default BelevingstrajectForm;
