import { Button, ButtonGroup, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import c from "./ColumnStyle";
import g from "src/utils/MuiStyle/Global";

/**
 * @typedef {object} Column
 * @property {string} [heading]
 * @property {string} title
 * @property {string} [content]
 * @property {string} [footer]
 * @property {boolean} [isButton]
 * @property {boolean} [isDisabledButton]
 * @property {string} [buttonLink]
 * @property {string} [buttonName]
 *
 * @param {Column} props
 */

const Column = (props) => {
  const {
    heading,
    title,
    content,
    footer,
    isButton = false,
    buttonLink = "/",
    buttonName = false,
    isDisabledButton = false,
  } = props;
  return (
    <>
      <Typography variant="h4" sx={c.cardTitleMobile}>
        {heading}
      </Typography>
      <Typography variant="h5" sx={c.cardBodyTitle}>
        {title}
      </Typography>
      {!isButton ? (
        <>
          <Typography variant="body1" sx={c.cardBody}>
            {content}
          </Typography>
          <Typography variant="h5" sx={c.cardFooter}>
            {footer}
          </Typography>
          {buttonName &&
            (isDisabledButton ? (
              <Button
                variant="outlined"
                sx={{
                  ...g.button.secondary,
                  ...{
                    marginTop: "8px",
                    pointerEvents: "none",
                  },
                }}
              >
                Huidig pakket
              </Button>
            ) : (
              <Button
                href={buttonLink}
                variant="contained"
                sx={{ marginTop: "8px" }}
              >
                {buttonName}
              </Button>
            ))}
        </>
      ) : (
        <Link to={buttonLink}>
          <Button variant="contained">{buttonName}</Button>
        </Link>
      )}
    </>
  );
};

export default Column;
