import { combineReducers } from "@reduxjs/toolkit/dist";
import profileAccount from "./profileAccount";
import questionOpen from "./questionOpen";
import userAccount from "./userAccount";

export default combineReducers({
  userAccount: userAccount,
  profileAccount: profileAccount,
  questionOpen: questionOpen,
});
