import { Box } from "@mui/material";
import { ListMenu } from "src/components/moleculs/Sidebar/ListMenu";
import useStore from "src/hooks/useStore";
import Card from "./Card";
import c from "./ItemsStyle";

const Items = () => {
  const store = useStore();
  const dataUser = store.profileAccount.data ?? { role: 0 };
  // @ts-ignore
  const userRole = dataUser.role ?? 3;

  const sxsLists = [c.item1, c.item2, c.item3, c.item4, c.item5];

  return (
    <Box sx={c.wrapper}>
      {ListMenu(userRole).mainMenu.map((item, index) => {
        if (item.name === "Home") return null;
        return (
          <Card
            key={item.name}
            to={item.url}
            title={item.name}
            sxs={sxsLists[index - 1]}
          />
        );
      })}
    </Box>
  );
};

export default Items;
