import { Button, TextField } from "@mui/material";
import axios from "axios";
import useForm from "src/hooks/useForm";
import SwalCatch from "src/utils/helpers/SwalCatch";
import SwalSuccess from "src/utils/helpers/SwalSuccess";
import c from "./FormStyle";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const LoginForm = () => {
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      password: "",
      repeatPassword: "",
    });

  const param = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    payload.code = param.token;

    if (payload.password === payload.repeatPassword) {
      axios
        .post("/api/password-reset", {
          code: param.token,
          password: payload.password,
        })
        .then((res) => {
          if (res.status === 200) {
            SwalSuccess("Uw wachtwoord is gewijzigd");
          }
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors;
          SwalCatch(errors);
        });
    } else {
      SwalCatch([{ message: "Wachtwoord komt niet overeen" }]);
    }
  };

  return (
    <Box sx={c.forms} component={"form"} onSubmit={handleSubmit} noValidate>
      <Link to="/">
        <Box
          sx={c.formLogo}
          component="img"
          alt="Logo"
          src="/images/logo.svg"
        ></Box>
      </Link>

      <TextField
        sx={c.field}
        id="password"
        label="Wachtwoord"
        variant="outlined"
        name="password"
        type="password"
        onChange={fnOnChange("password")}
        onInvalid={fnOnInvalid(
          "password",
          " Het wachtwoord moet ingevuld zijn!"
        )}
        required
        value={payload.password}
        // @ts-ignore
        error={valid.password}
        helperText={message.password}
      />

      <TextField
        sx={c.field}
        id="repeatPassword"
        label="Herhaal Wachtwoord"
        variant="outlined"
        name="repeatPassword"
        type="password"
        onChange={fnOnChange("repeatPassword")}
        onInvalid={fnOnInvalid(
          "password",
          " Het wachtwoord moet ingevuld zijn!"
        )}
        required
        value={payload.repeatPassword}
        // @ts-ignore
        error={valid.repeatPassword}
        helperText={message.repeatPassword}
      />

      <Button sx={c.button} variant="contained" type="submit">
        Verander wachtwoord
      </Button>
    </Box>
  );
};

export default LoginForm;
