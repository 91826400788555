const { AsSxObject } = require("src/utils/AsSxObject");

const InfoStyle = AsSxObject({
  wrapperInfoContent: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "42px",
  },
  titleInfoContent: {
    paddingTop: "0",
    "@media(max-width: 1020px)": {
      paddingTop: "0",
    },
  },
  listInfoContent: {
    display: "flex",
    paddingBlock: "32px",
    "@media(max-width: 1020px)": {
      paddingBlock: "20px",
      flexWrap: "wrap",
    },
  },
  keyListInfoContent: {
    fontFamily: "Metropolis-Light",
    fontSize: "18px",
    flex: "0 0 50%",
    "@media(max-width: 1020px)": {
      flex: "0 0 100%",
    },
  },
  valueListInfoContent: {
    fontFamily: "Metropolis-Bold",
    fontSize: "18px",
    flex: "0 0 50%",
    "@media(max-width: 1020px)": {
      flex: "0 0 100%",
    },
  },
  infoImgWrapper: {
    maxHeight: "338px",
    maxWidth: "338px",
    width: "100%",
    position: "relative",
    cursor: "pointer",
  },
  infoImg: {
    borderRadius: "20px",
    maxHeight: "338px",
    maxWidth: "338px",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  infoContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  wrapperInfo: {
    display: "flex",
    gap: "90px",
    "@media(max-width: 1020px)": {
      marginTop: "0",
      gap: "16px",
    },
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
    },
  },
});

export default InfoStyle;
