import Footer from "src/components/frontend/Footer/Footer";
import Navbar from "src/components/frontend/Navbar/Navbar";
import Title from "src/components/frontend/Title/Title";
import Vacatures from "src/components/frontend/Vacatures/Vacatures";

const VacatureOverview = () => {
  return (
    <>
      <Navbar />
      <Title text="Vacatures" />
      <Vacatures />
      <Footer />
    </>
  );
};

export default VacatureOverview;
