import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Home
 * @property {object} [sxs]
 *
 * @param {Home} param1
 */

export default function IcLocationLabel({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#2946D6" />
        <g clipPath="url(#clip0_221_20579)">
          <path
            d="M21.7467 13.6334C21.0467 10.5534 18.3601 9.16675 16.0001 9.16675C16.0001 9.16675 16.0001 9.16675 15.9934 9.16675C13.6401 9.16675 10.9467 10.5467 10.2467 13.6267C9.46673 17.0667 11.5734 19.9801 13.4801 21.8134C14.1867 22.4934 15.0934 22.8334 16.0001 22.8334C16.9067 22.8334 17.8134 22.4934 18.5134 21.8134C20.4201 19.9801 22.5267 17.0734 21.7467 13.6334ZM16.0001 16.9734C14.8401 16.9734 13.9001 16.0334 13.9001 14.8734C13.9001 13.7134 14.8401 12.7734 16.0001 12.7734C17.1601 12.7734 18.1001 13.7134 18.1001 14.8734C18.1001 16.0334 17.1601 16.9734 16.0001 16.9734Z"
            fill="#F4F6FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_221_20579">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
