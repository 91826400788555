import React, { useEffect, useState } from "react";

const useGanesisBlock = (data) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (data) {
      const contentInit = data.data.content;
      const ganesisRegex = /<!-- wp:genesis-custom-blocks\/hsform[^>]*\/-->/g;
      const ganesisDataRegex =
        /<!-- wp:genesis-custom-blocks\/hsform (.*?) \/-->/;

      const contentModified = contentInit.replace(ganesisRegex, (match) => {
        const matching = match.match(ganesisDataRegex);
        const jsonData = matching ? matching[1] : "{}";

        const dt = JSON.parse(jsonData);
        return `<a href="#" class="js-openForm" data-target="${
          dt ? dt.id : "button-1"
        }" style="color: #2946d6"><strong>${
          dt ? dt.text : "Button 1"
        }</strong></a><div class="js-hsform" id="${
          dt ? dt.id : "button-1"
        }" data-portal-id="${
          dt ? dt["portal-id"] : "27183479"
        }" data-form-id="${
          dt ? dt["form-id"] : "65132cb1-cbd4-46bc-82c6-bd0cc6c6c2bd"
        }" data-region="${dt ? dt.region : "eu1"}"></div>`;
      });

      setContent(contentModified);
    }
  }, [data]);

  return content;
};

export default useGanesisBlock;
