import c from "./QuestionOpenStyle";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import { AddSharp, ArrowBack, RemoveSharp } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setIsAnsweredFalse, setIsAnsweredTrue } from "src/store/questionOpen";
import useStore from "src/hooks/useStore";
import { useSwiper } from "swiper/react";
import { useSelector } from "react-redux";

const QuestionOpen = ({ item }) => {
  const [isLoad, setIsLoad] = useState(true);
  const [isOpenAnswer, setIsOpenAnswer] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  const swiper = useSwiper();
  const param = useParams();
  const question = item.questions.question
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/(&([^>]+);)/gi, "");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoad(false);
      // setIsOpenAnswer(() => {
      //   return Array.isArray(store.questionOpen.questionOpen)
      //     ? store.questionOpen.questionOpen.filter((_) => _.id === item.id)[0]
      //         ?.isAnswered
      //     : false;
      // });
    }

    return () => {
      setIsLoad(true);
      isMounted = false;
    };
  }, [param]);

  const handleMinus = (id) => () => {
    dispatch(setIsAnsweredFalse(id));
    setIsOpenAnswer(false);
  };

  const handlePlus = (id) => () => {
    dispatch(setIsAnsweredTrue(id));
    setIsOpenAnswer(true);
  };

  return (
    !isLoad && (
      <>
        <Typography variant="h2" sx={{ marginTop: "30px" }}>
          {question}
        </Typography>
        <SetInnerHtml
          className="faq-content"
          sxs={{
            ...c.answerWrapper,
            ...(isOpenAnswer ? c.answerWrapperOpen : {}),
          }}
        >
          {[item.content, item.questions.answer].join(" ")}
        </SetInnerHtml>
        <Box sx={c.footerSlide}>
          <Box sx={c.footerSlidePlusMinus}>
            <Button
              variant="contained"
              sx={isOpenAnswer ? c.circleIconTrue : c.circleIconFalse}
              onClick={isOpenAnswer ? handleMinus(item.id) : () => false}
            >
              <RemoveSharp />
            </Button>
            <Button
              variant="contained"
              sx={!isOpenAnswer ? c.circleIconTrue : c.circleIconFalse}
              onClick={!isOpenAnswer ? handlePlus(item.id) : () => false}
            >
              <AddSharp />
            </Button>
          </Box>
        </Box>

        <Box sx={c.footerBack}>
          <Button
            variant="contained"
            sx={c.circleIconTrue}
            onClick={() => swiper?.slideTo(0)}
          >
            <ArrowBack />
          </Button>
        </Box>
      </>
    )
  );
};

export default QuestionOpen;
