import c from "./CourseStyle";
import WrapperContent from "src/components/moleculs/WrapperContent";
import { Box, Typography } from "@mui/material";
import TitleMain from "src/components/atoms/TitleMain";
import DownloadList from "src/components/moleculs/DownloadList";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import SwalCatch from "src/utils/helpers/SwalCatch";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import Loading from "src/components/atoms/Loading";

const Course = ({ firstData }) => {
  const param = useParams();
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const slug = param.id ?? firstData?.slug;
  const title = slug.split("-").join(" ");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoading(true);
      axios
        .get(`/api/courses/${param.id ?? firstData?.slug}`)
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            const errors = res.data?.errors;
            SwalCatch(errors, true);
          }
          const newData = res.data.items;
          setDatas(newData);
          setIsLoading(false);
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors;
          SwalCatch(errors, true);
        });
    }

    return () => {
      setIsLoading(true);
      isMounted = false;
    };
  }, [param]);

  return (
    <WrapperContent isSm isLight>
      <Box sx={c.wrapper}>
        <TitleMain
          text={title}
          sxs={{
            h2: {
              "@media(max-width: 1366px)": {
                fontSize: "30px",
              },
            },
          }}
        />
        <Box sx={c.wrapperList}>
          {isLoading ? (
            <Loading
              sxs={{ position: "absolute", background: "transparent" }}
            />
          ) : (
            datas?.map((item, index) => {
              return (
                <Box
                  key={`${item.title.split(" ").join("-")}-${index}`}
                  sx={c.subWrapperList}
                >
                  <SetInnerHtml sxs={{ whiteSpace: "pre-line" }}>
                    {item.content}
                  </SetInnerHtml>
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    </WrapperContent>
  );
};

export default Course;
