import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Slides
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Slides} param1
 */

export default function SlidesIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1327"
        data-name="Group 1327"
        xmlns="http://www.w3.org/2000/svg"
        width="25.784"
        height="24.454"
        viewBox="0 0 25.784 24.454"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_46"
              data-name="Rectangle 46"
              width="25.784"
              height="24.454"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Group_990"
          data-name="Group 990"
          transform="translate(0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M25.783,1.96A1.779,1.779,0,0,0,23.834,0H2A1.789,1.789,0,0,0,0,2Q0,9.593,0,17.185a1.793,1.793,0,0,0,1.98,2H23.814a1.8,1.8,0,0,0,1.969-1.975q0-7.623,0-15.246"
            transform="translate(0)"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M36.585,159.811H17.3a1.356,1.356,0,0,0-1.007.339.934.934,0,0,0-.256.694c.007.3.144.984,1.26.984H36.582c1.115,0,1.255-.685,1.263-.98a.935.935,0,0,0-.255-.7,1.351,1.351,0,0,0-1.006-.34"
            transform="translate(-14.047 -140.019)"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M48.766,181.148H34.541l-.814,0c-.283,0-.5,0-.708.007a1,1,0,0,0-.008,2.009c.046,0,.092,0,.138,0h14.51l1.105,0a1.024,1.024,0,0,0,1.1-.986.957.957,0,0,0-.26-.694,1.178,1.178,0,0,0-.842-.336"
            transform="translate(-28.049 -158.711)"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_989"
        data-name="Group 989"
        xmlns="http://www.w3.org/2000/svg"
        width="25.784"
        height="24.454"
        viewBox="0 0 25.784 24.454"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_45"
              data-name="Rectangle 45"
              width="25.784"
              height="24.454"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Group_988"
          data-name="Group 988"
          transform="translate(0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_11"
            data-name="Path 11"
            d="M25.783,1.96A1.779,1.779,0,0,0,23.834,0H2A1.789,1.789,0,0,0,0,2Q0,9.593,0,17.185a1.793,1.793,0,0,0,1.98,2H23.814a1.8,1.8,0,0,0,1.969-1.975q0-7.623,0-15.246m-23.749-.3V2.01q5.805.006,11.61.006,5.046,0,10.1,0h.017c-.007,3.977-.007,8.019-.007,11.929V17.13H2.033V13.75c0-3.843,0-7.818-.006-11.727V2.01h.008Z"
            transform="translate(0)"
          />
          <path
            id="Path_12"
            data-name="Path 12"
            d="M36.585,159.811H17.3a1.356,1.356,0,0,0-1.007.339.934.934,0,0,0-.256.694c.007.3.144.984,1.26.984H36.582c1.115,0,1.255-.685,1.263-.98a.935.935,0,0,0-.255-.7,1.351,1.351,0,0,0-1.006-.34"
            transform="translate(-14.047 -140.019)"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M48.766,181.148H34.541l-.814,0c-.283,0-.5,0-.708.007a1,1,0,0,0-.008,2.009c.046,0,.092,0,.138,0h14.51l1.105,0a1.024,1.024,0,0,0,1.1-.986.957.957,0,0,0-.26-.694,1.178,1.178,0,0,0-.842-.336"
            transform="translate(-28.049 -158.711)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
