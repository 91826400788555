import {
  AccountIcon,
  CursusIcon,
  DocumentationIcon,
  FaqIcon,
  HomeIcon,
  ModellesIcon,
  PackageIcon,
  SlidesIcon,
} from "src/components/atoms/icons/Svg";

const ListMenu = (role = 3) => {
  const getMenuItem = (menus, item) => {
    let newMenu = [];
    menus.forEach((menu) => {
      if (item.indexOf(menu.name) !== -1) {
        newMenu.push(menu);
      }
    });

    return newMenu;
  };

  const mainMenu = [
    {
      name: "Home",
      url: "/dashboard",
      icon: <HomeIcon />,
      iconActive: <HomeIcon isActive />,
    },
    {
      name: "Cursus",
      url: "/course",
      icon: <CursusIcon />,
      iconActive: <CursusIcon isActive />,
    },
    {
      name: "Slides",
      url: "/slide",
      icon: <SlidesIcon />,
      iconActive: <SlidesIcon isActive />,
    },
    {
      name: "Documenten",
      url: "/document",
      icon: <DocumentationIcon />,
      iconActive: <DocumentationIcon isActive />,
    },
    {
      name: "Oefenvragen",
      url: "/oefenvragen",
      icon: <FaqIcon />,
      iconActive: <FaqIcon isActive />,
    },
    {
      name: "Modelles ondersteuning",
      url: "/modelles-ondersteuning",
      icon: <ModellesIcon />,
      iconActive: <ModellesIcon isActive />,
    },
  ];

  const menuZero = getMenuItem(mainMenu, ["Home", "Cursus"]);
  const menuOne = getMenuItem(mainMenu, ["Home", "Cursus", "Oefenvragen"]);
  const menuTwo = getMenuItem(mainMenu, [
    "Home",
    "Cursus",
    "Oefenvragen",
    "Documenten",
    "Modelles ondersteuning",
  ]);

  const listMenuObj = {
    0: menuZero,
    1: menuOne,
    2: menuTwo,
    3: mainMenu,
  };

  const ListMenu = {
    mainMenu: listMenuObj[role],
    footMenu: [
      {
        name: "Account",
        url: "/account",
        icon: <AccountIcon />,
        iconActive: <AccountIcon isActive />,
      },
      {
        name: "Lespakketten",
        url: "/lesson-packages",
        icon: <PackageIcon sxs={{ transform: "translateX(-5px)" }} />,
        iconActive: (
          <PackageIcon isActive sxs={{ transform: "translateX(-5px)" }} />
        ),
      },
    ],
  };

  return ListMenu;
};

export { ListMenu };
