import Sidebar from "src/components/moleculs/Sidebar";
import DashboardComponent from "src/components/backend/Dashboard";

export default function Dashboard() {
  return (
    <>
      <Sidebar />
      <DashboardComponent />
    </>
  );
}
