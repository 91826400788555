import c from "./TabPanelStyle";
import { Box } from "@mui/material";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`home-tabpanel-${index}`}
      aria-labelledby={`home-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={c.box}>{children}</Box>}
    </div>
  );
}
