import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Home
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Home} param1
 */

export default function HomeIcon({ isActive, sxs }) {
  return isActive ? (
    <svg
      id="Group_1022"
      data-name="Group 1022"
      xmlns="http://www.w3.org/2000/svg"
      width="29.369"
      height="29.364"
      viewBox="0 0 29.369 29.364"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_145"
            data-name="Rectangle 145"
            width="29.369"
            height="29.364"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_1021" data-name="Group 1021" clipPath="url(#clip-path)">
        <path
          id="Path_38"
          data-name="Path 38"
          d="M14.286,0h.8a4.063,4.063,0,0,1,2.049,1.323Q22.587,6.8,28.062,12.246a3.919,3.919,0,0,1,1.307,2.091v.688a.528.528,0,0,0-.045.1,2.676,2.676,0,0,1-2.733,2.253h-.372v.39q0,4.143,0,8.287a3.16,3.16,0,0,1-3.3,3.3q-2.179,0-4.358,0c-.742,0-1.057-.318-1.057-1.069q0-3.3,0-6.6a1.447,1.447,0,0,0-1.56-1.554c-.822,0-1.644,0-2.466,0a1.458,1.458,0,0,0-1.614,1.615q0,3.3,0,6.595a.891.891,0,0,1-1,1.009c-1.482,0-2.963,0-4.444,0a3.667,3.667,0,0,1-.823-.088A3.13,3.13,0,0,1,3.157,26.14c-.006-2.8,0-5.6,0-8.4V17.42a.443.443,0,0,0-.089-.033c-.143,0-.287,0-.43-.008A2.633,2.633,0,0,1,.375,16.035a2.691,2.691,0,0,1,.511-3.353Q6.551,7.014,12.215,1.344A4.113,4.113,0,0,1,14.286,0"
          transform="translate(0)"
        />
      </g>
    </svg>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.257"
        height="29.286"
        viewBox="0 0 29.257 29.286"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_144"
              data-name="Rectangle 144"
              width="29.257"
              height="29.286"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1020" data-name="Group 1020" transform="translate(0)">
          <g
            id="Group_1019"
            data-name="Group 1019"
            transform="translate(0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_37"
              data-name="Path 37"
              d="M29.257,15.262a3.055,3.055,0,0,1-.8,1.334,2.658,2.658,0,0,1-1.813.729c-.143,0-.286,0-.479,0v.34c0,2.8,0,5.609,0,8.414A3.129,3.129,0,0,1,23.724,29.2a3.568,3.568,0,0,1-.793.084c-1.488.008-2.976,0-4.465,0a.885.885,0,0,1-.99-.987c0-2.194-.024-4.389.01-6.582a1.526,1.526,0,0,0-1.632-1.637c-.791.027-1.584.029-2.375,0a1.531,1.531,0,0,0-1.645,1.653c.037,2.165.012,4.331.011,6.5,0,.743-.318,1.058-1.064,1.058q-2.175,0-4.35,0A3.152,3.152,0,0,1,3.15,26.006q-.005-4.15,0-8.3v-.35A9.019,9.019,0,0,1,2.2,17.27,2.558,2.558,0,0,1,.153,15.483a2.6,2.6,0,0,1,.54-2.643c.074-.087.156-.167.237-.248Q6.778,6.743,12.634.9A2.861,2.861,0,0,1,13.707.176,2.641,2.641,0,0,1,16.5.712c.5.475.976.967,1.462,1.452q5.08,5.079,10.163,10.155A3.936,3.936,0,0,1,29.257,14ZM19.192,27.569h.351c1.087,0,2.176-.032,3.262.01a1.55,1.55,0,0,0,1.66-1.669c-.034-3.09-.012-6.181-.012-9.272,0-.7.33-1.026,1.038-1.028.391,0,.783.01,1.173,0a.957.957,0,0,0,.862-1.3,1.842,1.842,0,0,0-.423-.565Q21.322,7.944,15.532,2.16A1.038,1.038,0,0,0,13.8,2.153L3.436,12.513c-.479.479-.963.953-1.434,1.439a.917.917,0,0,0-.2,1.058.929.929,0,0,0,.9.6c.362.006.725,0,1.087,0,.764,0,1.08.314,1.08,1.073q0,4.665,0,9.329a1.441,1.441,0,0,0,1.548,1.556q1.7,0,3.405,0c.093,0,.185-.008.306-.014,0-.129,0-.242,0-.354,0-1.936-.016-3.873.012-5.809a3.042,3.042,0,0,1,2.786-2.977c1.167-.078,2.344-.013,3.516-.009a1.192,1.192,0,0,1,.254.039,3.132,3.132,0,0,1,2.5,3.135c.01,1.87,0,3.739,0,5.609v.38"
              transform="translate(0 -0.001)"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
