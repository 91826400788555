import { Box, Container, Typography } from "@mui/material";
import c from "./ErrorPageStyle";

/**
 *
 * @typedef {object} IErrorPage
 *
 * @property {string} title
 * @property {string} [desc]
 *
 * @param {IErrorPage} param1
 */
const ErrorPage = ({ title, desc }) => {
  return (
    <Box sx={c.wrapper}>
      <Typography sx={c.title} variant="h1">
        {title}
      </Typography>
      <Typography variant="body1">{desc}</Typography>
    </Box>
  );
};

export default ErrorPage;
