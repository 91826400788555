import React from "react";
import { AddSharp, QuestionMarkSharp, RemoveSharp } from "@mui/icons-material";
import { Box, Button, Modal } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import TitleMain from "src/components/atoms/TitleMain";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import Swipper from "src/components/moleculs/Swipper";
import Question from "../Utils/Question";
import c from "./../SlidesStyle";
import g from "src/utils/MuiStyle/Global";
import { useSwiper, useSwiperSlide } from "swiper/react";

const SlidePerSlide = ({ item }) => {
  const [openQuestion, setOpenQuestion] = useState(false);
  const [openedIndices, setOpenedIndices] = useState(0);
  const [content, setContent] = useState("");
  const id = item.title.split(" ").join("-");
  const swiper = useSwiperSlide();
  const swiperUse = useSwiper();

  useEffect(() => {
    const contentFor = item.elements
      .map((item, i) => {
        return i <= openedIndices ? item : "";
      })
      .join(" ");

    setContent(contentFor);

    if (swiper.isActive) {
      if (openedIndices > item.elements.length - 1) {
        swiperUse.slideNext();
      }

      if (openedIndices < 0) {
        swiperUse.slidePrev();
      }
    }

    return () => {
      setContent("");
    };
  }, [openedIndices]);

  useEffect(() => {
    document.addEventListener("keydown", function (e) {
      if (e.code === "ArrowRight") {
        onAdd();
      } else if (e.code === "ArrowLeft") {
        onRemove();
      }
    });

    return () => {
      document.removeEventListener("keydown", function (e) {
        if (e.code === "ArrowRight") {
          onAdd();
        } else if (e.code === "ArrowLeft") {
          onRemove();
        }
      });
    };
  }, []);

  // useEffect(() => {
  //   setOpenedIndices(0);
  // }, [swiper]);

  const onAdd = () => {
    setOpenedIndices((prev) => prev + 1);
  };
  const onRemove = () => setOpenedIndices((prev) => prev - 1);

  return (
    <Box>
      <TitleMain text={item.title} />
      <SetInnerHtml
        className={`slide-comp slide-comp${id}`}
        key={id}
        sxs={{ paddingBottom: "56px" }}
      >
        {content}
      </SetInnerHtml>
      <Box sx={c.footerSlideFixed}>
        <Button
          variant="contained"
          sx={
            item.questions.length === 0
              ? g.button.circleIconFalse
              : g.button.circleIconTrue
          }
          onClick={() =>
            item.questions.length === 0 ? false : setOpenQuestion(true)
          }
        >
          <QuestionMarkSharp />
        </Button>
        <Box sx={c.footerSlidePlusMinus}>
          <Button
            className="minusss"
            variant="contained"
            sx={
              openedIndices <= 0
                ? g.button.circleIconFalse
                : g.button.circleIconTrue
            }
            onClick={onRemove}
          >
            <RemoveSharp />
          </Button>
          <Button
            className="plusss"
            variant="contained"
            sx={
              openedIndices >= item.elements.length - 1
                ? g.button.circleIconFalse
                : g.button.circleIconTrue
            }
            onClick={
              openedIndices < item.elements.length - 1 ? onAdd : () => false
            }
          >
            <AddSharp />
          </Button>
        </Box>
      </Box>
      <Modal
        open={openQuestion}
        onClose={() => setOpenQuestion(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={c.modal}
      >
        <Box sx={c.modalWrapper}>
          <Swipper
            title={null}
            handlePopupProps={() => setOpenQuestion(false)}
            iconToggle
            initialSlide={0}
            onSlideChange={() => 0}
            allowTouchMove={false}
            datas={item.questions}
            content={(item) => <Question item={item} />}
            footer={() => ""}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default SlidePerSlide;
