import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const ContactStyle = AsSxObject({
  container: { ...g.container, ...{ padding: "0 16px" } },
  wrapperTop: {
    marginTop: "30px",
    "@media(min-width: 600px)": {
      marginTop: "60px",
    },
    "@media(min-width: 1020px)": {
      paddingLeft: "65px",
      paddingRight: "65px",
      marginTop: "112px",
    },
  },
  wrapperBottom: {
    paddingBottom: "30px",
    marginTop: "30px",
    "@media(min-width: 600px)": {
      marginTop: "60px",
      paddingBottom: "112px",
    },
    "@media(min-width: 1020px)": {
      paddingBottom: "112px",
      // marginBottom: "-145px",
    },
    "@media(min-width: 600px) and (max-width: 1020px)": {
      // marginBottom: "-182px",
    },
    "@media(min-width: 0px) and (max-width: 600px)": {
      // marginBottom: "-115px",
    },
  },
  form: {
    maxWidth: "800px",
    marginTop: "30px",
    "@media(min-width: 600px)": {
      marginTop: "60px",
    },
    "@media(min-width: 1020px)": {
      marginTop: "112px",
    },
  },
  information: {
    display: "flex",
    alignItems: "stretch",
    columnGap: "86px",
    "@media(max-width: 1020px)": {
      flexWrap: "wrap",
      rowGap: "42px",
    },
    "@media(max-width: 600px)": {
      rowGap: "30px",
    },
  },
  img: {
    maxWidth: "450px",
    width: "100%",
    height: "471px",
    objectFit: "cover",
    borderRadius: "20px",
    "@media(max-width: 1020px)": {
      flex: "0 0 100%",
      marginInline: "auto",
      height: "300px",
    },
    "@media(max-width: 600px)": {
      height: "250px",
    },
  },
  brands: {
    width: "100%",
    objectFit: "cover",
    borderRadius: "20px",
    "@media(max-width: 1020px)": {
      flex: "0 0 100%",
      marginInline: "auto",
      height: "300px",
    },
    "@media(max-width: 600px)": {
      height: "250px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media(max-width: 1020px)": {
      flex: "0 0 100%",
      gap: "30px",
    },
    "@media(max-width: 600px)": {
      gap: "20px",
    },
  },
  title: {},
  desc: {},
  quote: {
    backgroundColor: g.color.secondary,
    borderRadius: "32px",
    padding: "35px 38px",
    fontFamily: "Metropolis-RegularItalic",
    color: g.color.primary,
  },
  listWrapper: {
    marginTop: "110px",
    display: "flex",
    flexDirection: "column",
    gap: "75px",
    "@media(max-width: 1020px)": {
      marginTop: "60px",
      gap: "45px",
    },
    "@media(max-width: 600px)": {
      gap: "24px",
    },
  },
  listTitle: {
    title: {
      fontFamily: "Metropolis-medium",
    },
  },
  listItems: {
    display: "flex",
    columnGap: "200px",
    "@media(max-width: 1020px)": {
      columnGap: "40px",
    },
    "@media(max-width: 600px)": {
      flexDirection: "column",
      columnGap: "40px",
    },
  },
  listItemsCol: {},
  listButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  listCaption: {
    fontFamily: "Metropolis-semiBold",
  },
  listEmpty: {
    borderBottom: "4px solid",
    borderBottomColor: "transparent",
    "@media(max-width: 600px)": {
      display: "none",
    },
    icon: {
      visibility: "hidden",
    },
  },
});

export default ContactStyle;
