const { AsSxObject } = require("src/utils/AsSxObject");

const RenderedImageStyle = AsSxObject({
  slideImage: {
    width: "100%",
    objectFit: "contain",
    height: "auto",
    marginBottom: "16px",
  },
});

export default RenderedImageStyle;
