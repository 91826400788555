import React from "react";
import c from "./CardStyle";
import g from "src/utils/MuiStyle/Global";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRight from "src/components/atoms/icons/Svg/ArrowRight";
/**
 * @typedef {object} TCard
 * @property {string} slug
 * @property {string} title
 * @property {string} desc
 * @property {string} image
 * @property {string} published
 * @property {string} category
 *
 * @param {TCard} param0
 * @returns
 */
const Card = ({ slug, title, desc, image, published, category }) => {
  const ignoredCategory = ["Uncategorized", "Geen categorie"];
  return (
    <Box className="mi-Blog__Card" sx={c.wrapper}>
      <Box
        sx={c.image}
        component={"img"}
        src={image ?? "https://dummyimage.com/300x200/dfdfdf/000.jpg"}
        alt="Card Image"
        height={248}
        width={368}
      />
      <Box sx={c.content}>
        <Box sx={c.content__term}>
          {!ignoredCategory.includes(category) && (
            <Typography
              sx={{ ...g.button.secondary, ...c.content__term__cat }}
              variant="body2"
            >
              {category}
            </Typography>
          )}
          <Typography sx={c.content__term__date} variant="body2">
            {published}
          </Typography>
        </Box>
        <Link to={`/blogs/${slug}`}>
          <Typography sx={c.content__title} variant="h4">
            {title}
          </Typography>
        </Link>
        <Typography sx={c.content__shortdesc} variant="body1">
          {desc}
        </Typography>
        <Link to={`/blogs/${slug}`}>
          <Button sx={c.content__button}>
            Lees Meer <ArrowRight />
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Card;
